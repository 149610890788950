.CreateAd-wrapper {
  text-align: center;
  align-items: center;
  height: 100vh;
  background-color: #FFFDF9;
  margin-top: 40px;
}
.CreateAd-wrapper .ad-creation-area {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}
.CreateAd-wrapper .ad-creation-area .PostAd {
  right: 24px;
  position: absolute;
  margin-top: 5px;
  display: inline-block;
  outline: none;
  height: 35px;
  width: 150px;
  border: solid #161616;
  color: #161616;
  font-size: 16px;
  text-align: center;
  cursor: pointer;
}
.CreateAd-wrapper .ad-creation-area .AdUpload {
  position: relative;
  margin: 10px;
}
.CreateAd-wrapper .ad-creation-area .AdUpload img {
  width: 250px;
  height: 500px;
  display: block;
  box-shadow: 0px 16px 18px 0px rgba(0, 0, 0, 0.16);
}
.CreateAd-wrapper .ad-creation-area .AdUpload a {
  display: block;
  margin-top: 2px;
  height: 24px;
  width: 250px;
  overflow-x: scroll;
  text-decoration: none;
  color: #161616;
  opacity: 0.5;
  font-size: 14px;
}
.CreateAd-wrapper .ad-creation-area .AdUpload button {
  position: absolute;
  bottom: 26px;
  right: 0px;
  background: gray;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
}
.CreateAd-wrapper .ad-creation-area .AdUpload button:hover {
  background-color: #D9A800;
}
.CreateAd-wrapper .ad-creation-area .fileUpload #upload-button {
  display: none;
}
.CreateAd-wrapper .ad-creation-area .fileUpload label {
  display: block;
  cursor: pointer;
  margin: 10px;
  height: 600px;
  padding: 5px;
}
.CreateAd-wrapper .ad-creation-area .fileUpload label .Ad-image-area {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px dashed gray;
  padding: 20px;
  height: 600px;
  width: 300px;
  justify-content: center;
  background-color: #fff;
  box-shadow: 0px 16px 18px 0px rgba(0, 0, 0, 0.16);
}
.CreateAd-wrapper .ad-creation-area .fileUpload label .Ad-image-area img {
  width: 300px;
}
.CreateAd-wrapper .ad-creation-area .fileUpload label .Ad-image-area .add-icon {
  font-size: 24px;
}
.CreateAd-wrapper .ad-creation-area .fileUpload label .Ad-image-area p {
  margin-top: 5px;
  font-size: 12px;
}
.CreateAd-wrapper .ad-creation-area .fileUpload .link-input {
  width: 200px;
  padding: 10px;
  font-size: 16px;
  margin-top: 10px;
}
.CreateAd-wrapper .ad-creation-area .fileUpload button {
  margin-top: 30px;
  background-color: #fff;
  cursor: pointer;
  padding: 10px 20px;
  font-size: 16px;
  margin-top: 10px;
}
.CreateAd-wrapper .ad-creation-area .fileUpload button:hover {
  background-color: #D9A800;
  color: #fff;
}

