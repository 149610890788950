.Signedin {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center; /* Center items horizontally */
    justify-content: center; /* Center items vertically */
    position: relative;
    color: #FFFDF9;
    background-position: center;
    background-size: cover;

    background-image: linear-gradient(rgba(13, 13, 13, 0.4), rgba(13,13 , 13, 0.7)),url('Images/Black.jpg');
  
    .wrapper {
      margin: auto;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
        
      img {
        height: 85px;
        width: 85px;
        background-color: #D9D9D9;
        border-radius: 50px;
        margin-top: 20px;
      }
  
      p {
        color: #FCFCFC;
        font-family: 'Roboto';
      }
  
      h3 {
        color: #FCFCFC;
        font-family: 'Roboto';
        margin: 20px 0;
      }
  
      #loggedin {
        margin-top: 10px;
      }
  
      .option-wrapper {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin: 20px 0;
  
        .options {
          height: 154px;
          width: 233px;
          background-color: #FCFCFC;
          border-radius: 5px;
          border-bottom: 13px solid #D9A800;
          color: black;
          margin: 6px;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          font-family: 'Roboto';
          font-size: 18px;
          cursor: pointer;
          text-decoration: none;
          color: black; /* Ensure link text is black */
          transition: transform 0.2s;
  
          &:hover {
            transform: scale(1.05);
          }
        }
      }
  
      #log-out, #backhome {
        color: #FCFCFC;
        cursor: pointer;
        margin: 10px 0;
      }
      #backhome{
        color: #D9A800;
        text-decoration: none;
      }
    }
  }
  