.CreateAd-wrapper{
    text-align: center;
    align-items: center;
    // padding: 20px;
    height: 100vh;
    background-color: #FFFDF9;
    margin-top: 40px;
    

    .ad-creation-area{
        display: flex;
        justify-content: center;
        align-items: center;
        // background-color: aqua;
        min-height: 100vh;

        .PostAd{
            right: 24px;
            position: absolute;
            // margin: auto;
           margin-top: 5px;
    
            display: inline-block;
            outline: none;
            height: 35px;
            width: 150px;
            border: solid  #161616;
          
            color: #161616;
            font-size: 16px;
            text-align: center;
            // margin: auto;
            cursor: pointer;
        }

        .AdUpload{
            position: relative;
            margin: 10px;
            // background-color: red;

            img{
                width: 250px;
                height: 500px;
                display: block;
                box-shadow: 0px 16px 18px 0px rgba(0, 0, 0, 0.16);
            }

            a{
                display: block;
                margin-top: 2px;
                
                height: 24px;
                width: 250px;
                overflow-x: scroll;
                text-decoration: none;
                color: #161616;
                opacity: .5;
                font-size: 14px;
                
            }

            button{
                position: absolute;
                bottom: 26px;
                right: 0px;
                background: gray;
                color: white;
                border: none;
                padding: 5px 10px;
                cursor: pointer;

                &:hover{
                    background-color: #D9A800;
                }
            }
        }

        .fileUpload{

            #upload-button{
                display: none;
            }

            label{
                display: block;
                cursor: pointer;
                margin: 10px;
                height: 600px;
                padding: 5px;

                .Ad-image-area{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    border:1px dashed gray;
                    padding: 20px;
                    height: 600px;
                    width: 300px;
                    justify-content: center;
                    background-color: #fff;
                    box-shadow: 0px 16px 18px 0px rgba(0, 0, 0, 0.16);

                    img{
                        width: 300px;
                    }

                    .add-icon{
                        font-size: 24px;
                    }

                    p{
                        margin-top: 5px;
                        font-size: 12px;
                    }
                }
            }

            .link-input{
                width: 200px;
                padding: 10px;
                font-size: 16px;
                margin-top: 10px;
            }

            button{
                margin-top: 30px;
                background-color: #fff;
                cursor: pointer;

                padding: 10px 20px;
                font-size: 16px;
                margin-top: 10px;

                &:hover{
                    background-color: #D9A800;
                    color: #fff;
                }
            }
        }
        
    }
}