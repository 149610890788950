.content {
  width: 100%;
  display: flex;
  font-family: Georgia, "Times New Roman", Times, serif;
}
.content .row {
  width: 100%;
  padding: 0;
  margin: 0;
}
.content .row .col {
  padding: 0;
  margin: 0;
  border: 0px 3px solid #FFFDF8;
  display: flex;
}
.content .row .col img {
  width: 90%;
  margin: auto;
}
.content .row #bottom-image {
  background-color: #07070A;
}
.content .row .col-4 {
  background-color: #161616;
}
.content .row .col-8 {
  display: flex;
}
.content .row #top-text {
  background-color: #161616;
  justify-content: center;
  display: flex;
}
.content .row #top-text img {
  height: 54px;
  width: 54px;
  position: absolute;
  margin-top: 12%;
}
.content .row #top-text h3 {
  color: #FCFCFC;
  margin-top: 18%;
  font-size: 22px;
  font-weight: 300;
  text-align: center;
  position: absolute;
}
.content .row #top-text a {
  margin-top: 22%;
  position: absolute;
  text-decoration: none;
}
.content .row #top-text a p {
  color: #D9A800;
  font-size: 24px;
  font-weight: 300;
}
.content .row #bottom-text {
  background-color: #EAD9C4;
  display: flex;
}
.content .row #bottom-text .magz-promo {
  position: absolute;
  margin: auto;
  min-height: 60px;
  display: flex;
  justify-content: center;
  margin-top: 10%;
  margin-left: 10%;
}
.content .row #bottom-text .magz-promo img {
  height: 54px;
  width: 54px;
}
.content .row #bottom-text .magz-promo h3 {
  text-align: center;
  margin-left: 20px;
  margin-top: 15px;
  font-size: 22px;
}
.content .row #bottom-text p {
  margin-top: 15%;
  position: absolute;
  margin-left: 10%;
}
.content .row #bottom-text p a {
  text-decoration: none;
  font-weight: 400;
  color: #D9A800;
}

@media (max-width: 768px) {
  .content {
    flex-direction: column;
  }
  .content .row .col {
    border: none;
  }
  .content .row #top-text img {
    margin-top: 10%;
    height: 16px;
    width: 16px;
  }
  .content .row #top-text h3 {
    margin-top: 15%;
    font-size: 1rem;
  }
  .content .row #top-text a {
    margin-top: 20%;
  }
  .content .row #top-text a p {
    font-size: 1rem;
    text-align: center;
  }
  .content .row #bottom-text .magz-promo {
    margin-top: 5%;
    margin-left: 1px;
  }
  .content .row #bottom-text .magz-promo img {
    height: 16px;
    width: 16px;
    position: absolute;
  }
  .content .row #bottom-text .magz-promo h3 {
    margin-top: 10%;
    font-size: 0.9rem;
  }
  .content .row #bottom-text p {
    margin-top: 20%;
    font-size: 0.7rem;
    text-align: left;
    left: 1px;
    width: 250px;
  }
}

