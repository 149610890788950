.contact_us {
  height: 75vh;
  width: 100%;
  background-color: #D9A800;
  display: flex;
}
.contact_us .contacts-wrapper {
  height: 70%;
  width: 100%;
  margin: auto;
  display: flex;
}
.contact_us .contacts-wrapper .contact-details {
  height: 100%;
  width: 50%;
  left: 0;
}
.contact_us .contacts-wrapper .contact-details .detail {
  height: 100px;
  width: 500px;
  border-radius: 5px;
  background-color: #FFFDF9;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  box-shadow: 10px 10px 8px rgba(22, 22, 22, 0.1);
  margin: auto;
  margin-top: 34px;
  display: flex;
}
.contact_us .contacts-wrapper .contact-details .detail .contact-icon {
  height: 40px;
  width: 40px;
  margin: auto;
  margin-left: 50px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  display: flex;
}
.contact_us .contacts-wrapper .contact-details .detail h4 {
  font-size: 22px;
  font-weight: 500;
  position: absolute;
  margin-left: 130px;
  margin-top: 22px;
  color: #D9A800;
}
.contact_us .contacts-wrapper .contact-details .detail p {
  font-size: 16px;
  font-weight: 500;
  position: absolute;
  margin-left: 130px;
  margin-top: 54px;
  color: #161616;
}
.contact_us .contacts-wrapper .get-in-touch {
  height: 100%;
  width: 50%;
  right: 0;
}
.contact_us .contacts-wrapper .get-in-touch .GIT-content {
  height: 140px;
  width: 90%;
  margin-right: 100px;
  margin-top: 20px;
}
.contact_us .contacts-wrapper .get-in-touch .GIT-content h3 {
  font-size: 36px;
}
.contact_us .contacts-wrapper .get-in-touch .GIT-content p {
  margin-top: 20px;
}
.contact_us .contacts-wrapper .get-in-touch form {
  height: 270px;
  width: 90%;
}
.contact_us .contacts-wrapper .get-in-touch form .inpute-box {
  height: 50px;
  width: 300px;
  background-color: #FCFCFC;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border: none;
  box-shadow: 10px 10px 8px -5px rgba(22, 22, 22, 0.1);
  border: none;
  margin: auto;
  outline: none;
  font-family: sans-serif;
}
.contact_us .contacts-wrapper .get-in-touch form .inpute-box:focus {
  border: none;
}
.contact_us .contacts-wrapper .get-in-touch form .inpute-box[type=email] {
  padding: 12px 20px;
  box-sizing: border-box;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  outline: none;
}
.contact_us .contacts-wrapper .get-in-touch form .inpute-box[type=name] {
  padding: 12px 20px;
  box-sizing: border-box;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  outline: none;
}
.contact_us .contacts-wrapper .get-in-touch form .inpute-box[type=text] {
  padding: 12px 20px;
  box-sizing: border-box;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  outline: none;
  top: 5px;
}
.contact_us .contacts-wrapper .get-in-touch form #email {
  margin-left: 20px;
}
.contact_us .contacts-wrapper .get-in-touch form #message {
  margin-top: 20px;
  width: 625px;
  height: 100px;
}
.contact_us .contacts-wrapper .get-in-touch form button {
  height: 55px;
  width: 625px;
  color: #FCFCFC;
  font-weight: bold;
  font-size: 16px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border: 2px solid #FCFCFC;
  margin-top: 10px;
  background-color: #D9A800;
}
.contact_us .contacts-wrapper .get-in-touch form button:hover {
  background-color: #FCFCFC;
  color: #D9A800;
  cursor: pointer;
}

@media (max-width: 768px) {
  .contact_us {
    height: 125vh;
  }
  .contact_us .contacts-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    overflow-x: hidden;
  }
  .contact_us .contacts-wrapper .contact-details {
    position: absolute;
    width: 100%;
    height: auto;
    margin-top: 10%;
  }
  .contact_us .contacts-wrapper .contact-details .detail {
    width: 95%;
  }
  .contact_us .contacts-wrapper .get-in-touch {
    position: absolute;
    width: 100%;
    height: 550px;
    display: flex;
    margin-top: 480px;
  }
  .contact_us .contacts-wrapper .get-in-touch .GIT-content {
    justify-content: center;
    width: 100%;
    position: absolute;
    top: 0;
  }
  .contact_us .contacts-wrapper .get-in-touch .GIT-content h3 {
    position: absolute;
    left: 16px;
  }
  .contact_us .contacts-wrapper .get-in-touch .GIT-content p {
    position: absolute;
    left: 16px;
    right: 16px;
    top: 40px;
  }
  .contact_us .contacts-wrapper .get-in-touch form {
    display: flex;
    width: 100%;
    height: 350px;
    position: absolute;
    bottom: 5%;
    justify-content: center;
  }
  .contact_us .contacts-wrapper .get-in-touch form .inpute-box[type=name] {
    position: absolute;
    width: 95%;
    top: 10px;
  }
  .contact_us .contacts-wrapper .get-in-touch form .inpute-box[type=email] {
    width: 95%;
    margin-left: 0 !important;
    position: absolute;
    top: 80px;
  }
  .contact_us .contacts-wrapper .get-in-touch form .inpute-box[type=text] {
    position: absolute;
    width: 95% !important;
    top: 130px;
  }
  .contact_us .contacts-wrapper .get-in-touch form button {
    width: 95% !important;
    position: absolute;
    top: 260px;
  }
}

