.commentsSection {
    width: 75%;
    margin-left: 12.5%;
    // background: green;
    padding: 20px;

    h2 {
        color: #262626;
        font-family: Roboto;
        font-size: 30px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 20px;
    }

    .comment {
      border-bottom: 1px solid #ccc;
      padding: 15px 0;

      p{
        color: #262626;
        font-family: Roboto;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
   
      }
    }

    textarea {
      width: 100%;
      height: 100px;
      margin-bottom: 15px;
      padding: 10px;
      resize: none;
        flex-shrink: 0;

        outline: none; // Remove the default focus border
        border: none;

        placeholder{
            color: #737373;
            font-family: Roboto;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }

    button {
        display: inline-block;
        outline: none;
        height: 40px;
        width: 150px;
        border: solid  #161616;
        background-color: #D9A800;
        color: #161616;
        font-size: 16px;
        text-align: center;
        margin: auto;
        margin-bottom: 20px;
        cursor: pointer;
    }

    button:hover{
        background-color:#FCFCFC;
        cursor: pointer;
    }

    button:active {
        transform: translateY(1px);
        -webkit-transform: translateY(1px);
        -moz-transform: translateY(1px);
        -ms-transform: translateY(1px);
        -o-transform: translateY(1px);
    }

    .Pplsthought{
       margin-top: 50px;
    }

    // Media Query for Mobile Devices
    @media (max-width: 767px) {

        width: 95%;
        margin-left: 0px;
        margin: auto;;

        h2{
          font-size: 24px;
          margin-left: 0px;
        }
      }

  }