@import url('https://fonts.googleapis.com/css2?family=Hurricane&display=swap');
.magazine{
    // margin-top: 5px;
    // margin-bottom: 3px;
    height: 75vh;
    width: 100%;
    background-color: #FFFDF8;
    // display: flex;

    overflow: none;
    // white-space: nowrap;

    .magazine_hero{
        height: 250px;
        display: flex;
        height: 210px;
        width: 100%;

        background-color: #FFFDF8;
        background-image: url('./Images/banner_bg.svg');
        background-position: center;
        background-size: cover;
        display: flex;

        h3{
            color: #000;
            font-family: 'Hurricane';
            font-size: 105px;
            font-style: normal;
            font-weight: 400;
            line-height: 173px; /* 164.762% */
            text-align: center;
            margin: auto;
        }
    }

    .landing_mags_wrapper{
        height: 50vh;
        width: 95%;
        display: flex;
        overflow: auto;
        white-space: nowrap;
        margin: auto;

        .magz{
            width: 100%;
            height: 100%;
            background-size:contain;
            background-repeat: no-repeat;
            background-position: center;
            padding: 5px;

            img{
                width: 220px;
                height: 310.59px;
                flex-shrink: 0;
                transition: all .2s ease-in-out;
                border-radius: 5px;
                -webkit-border-radius: 5px;
                -moz-border-radius: 5px;
                -ms-border-radius: 5px;
                -o-border-radius: 5px;
}

            h4{
                font-size: 16px;
                color: #262626;
                margin-top: 15px;
                text-wrap: wrap;
            }
        }

        .magz:hover{
            cursor: pointer;
            img{
                transform: scale(1.1);
                box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
                -webkit-transform: scale(1.1);
                -moz-transform: scale(1.1);
                -ms-transform: scale(1.1);
                -o-transform: scale(1.1);
            }

            h4{
                font-weight: bolder;
            }
        }

        #View-more{
            height: 100%;

            text-decoration: none;

            .next{
                // background-color: #D9A800;
                width: 220px;
                display: flex;
                
                img{
                    // height: 162px;
                    // width: 220x;
                    // margin: auto;
                    // margin-top: 25%;
                    // background-color: aqua;
                }
            }

            h4{
                // text-align: center;
                // font-weight: 400;
            }
        }

        #View-more:hover{
            .next{
                transform: scale(1.0);
                -webkit-transform: scale(1.0);
                -moz-transform: scale(1.0);
                -ms-transform: scale(1.0);
                -o-transform: scale(1.0);

                img{
                    transform: scale(1.1);
                    box-shadow: none ;
                    -webkit-transform: scale(1.1);
                    -moz-transform: scale(1.1);
                    -ms-transform: scale(1.1);
                    -o-transform: scale(1.1);
                }
            }

            h4{
                color:#D9A800;

            }
        }
    }

      // Media Query for Mobile Devices
    @media (max-width: 767px) {

        flex-wrap: wrap;
        // flex-wrap:inherit;
        // overflow-x:auto;
        // overflow-y:hidden;
        display: block;
        // overscroll-behavior-inline: auto;
        // white-space: wrap;
        // background-color: #992730;

        .magazine_hero{
            h3{
                font-size: 114px;
            }
        }

        .magz {
            display: inline-block;
            width: 300px;

            .magz-footer {
                position: absolute;
                bottom: 0;
                width: 100%;

                h4 {
                    position: static;
                    margin-left: 0;
                    text-align: center;
                }
            }
        }
    }
}