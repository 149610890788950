.footer-area{
    height: 75vh;
    width: 100%;
    // background-color:aquamarine;
    display: flex;
    position: absolute;

    .newsletter{
        height: 50vh;
        width: 100%;
        background-color: #D9A800;
        // display: flex;
        justify-content: center;
        text-align: center;
        padding: 0;
        margin: 0;
        position: absolute;
        color:#FCFCFC;
        

        h2{
            font-size: 36px;
            font-family: roboto thin;
            margin-top: 100px;
        }

        p{
            font-size: 14px;
        }

        .mail-area{
            margin: auto;
            margin-top:50px;
            background-color:#FCFCFC;
            height: 50px;
            display: flex;
            width: 600px;
            padding: 1px 5px 1px 5px;

            input{
                height: 40px;
                width: 500px;
                background-color: #FCFCFC;
                border: none;
                margin: auto;
                outline: none;
                
            }

            input[type=email]:focus {
                border: none;
            }

            input[type=email] {
                padding: 12px 20px;
                box-sizing: border-box;
                -webkit-transition: 0.5s;
                transition: 0.5s;
                outline: none;
            }
              

            button{
                display: inline-block;
                outline: none;
                height: 40px;
                width: 150px;
                border: solid  #161616;
                background-color: #D9A800;
                color: #161616;
                font-size: large;
                text-align: center;
                margin: auto;
            }

            button:hover{
                background-color:#FCFCFC;
                cursor: pointer;
            }

            button:active {
                transform: translateY(1px);
                -webkit-transform: translateY(1px);
                -moz-transform: translateY(1px);
                -ms-transform: translateY(1px);
                -o-transform: translateY(1px);
            }
            
        }
    }

    .letter-image{
        position: absolute;
        bottom: 130px;

        img{
            height: 350px;
            width: 280px;
            display: flex;
        }
    }

    #left-image{
        margin-left:100px;
    }

    #right-image{
        margin-right:60px;
        right: 0;
        height: 450px;
        width: 350px;
        bottom: 60px;

        img{
            height: 100%;
            width: 100%;
        }
    }



    .footer{
        position: absolute;
        bottom: 0;
        height: 25vh;
        width: 100%;
        background-color: #FCFCFC;
        background-color: #FFFDF8;
        background-image: url('./Images/banner_bg.svg');
        background-position: center;
        background-size: cover;
        display: flex;
        justify-content: center;

        .bottom-nav{
            height: 50px;
            width: 100%;
            // background-color: red;
            margin-top: 50px;
            position: absolute;

            nav{
                --ff-size: clamp(1rem, 3vw, 1.1rem);
                display: flex;
                justify-content: center;
                align-items: center;
        
                position: auto;
                isolation: isolate;
                z-index: 1;
        
                font-size: var(--ff-size);
                letter-spacing: -0.5px;
        
                transition: all 0.3s ease;

            }
        
            nav:is(:hover, :focus-within) .nav__slider {
                opacity: 1;
                transition: opacity 0.1s ease 0.05s;
            }
        
        
            .nav__slider {
                position: absolute;
                // top: 0;
                left: 0;
                z-index: -1;
            
                opacity: 0;
                border-radius: 0.25rem;
                background-color: rgba(255, 255, 255, 0.9);
                box-shadow: inset -1px -1px 1px 0 rgba(45, 45, 45, 0.25),
                    0 0 calc(var(--ff-size) * 1) rgba(255, 255, 255, 0.075);
            
                transition: opacity 0.2s ease;
            }
            
            .b-nav__item {
                color: #262626;
                text-decoration: none;
            
                padding: 0.25rem calc(var(--ff-size) / 2.5);
            
                cursor: pointer;
            
                transition: color 0.2s ease;
            }
            
            .b-nav__item:is(:hover, :focus-visible, :active) {
                color: #D9A800;
                font-weight: bold;
            }
        
        }

        hr{
            position: absolute;
            margin: auto;
            margin-top: 100px;
            background-color: #161616;
            width: 800px;
            height: .5px;

        }

        .bottom-socials-wrapper{

            position: absolute;
            height: 40px;
            width: 100%;
            bottom: 50px;
            display: flex;

            .socials{
                width:250px;
                display: flex;
                margin: auto;
    
                .social{
                    margin: auto;
                    display: flex;
                    height: 30px;
                    width: 30px;
                    padding: 5px;
                }
    
                #social-one{
                    width: 55px;
                }  
            }
        }

        .copyright-wrapper{
            height: 30px;
            width: 100%;
            // background-color: #09c91c;
            position: absolute;
            bottom: 15px;
            display: flex;
            justify-content: center;

            .copyright-style{
                font-size: 12px;
                font-family: roboto;
                color: #0D0D0D;
            }

            #copyright{
                position: absolute;
                left: 50px;
            }

            #sponsor{
                position: absolute;
                right: 50px;

                a{
                    text-decoration: none;
                    color: #0D0D0D;
                }
            }
        }
    }
}

  // Media Query for Mobile
  @media (max-width: 768px) {
    .footer-area {
      flex-direction: column;
      height: 100vh;
      width: 100%;
      padding: 0;

      .newsletter {
        position: static;
        height: 60%;

        h2 {
          margin-top: 50px;
          font-size: 1.5rem;
        }

        p{
          padding: 16px;
        }

        .mail-area{
            width: 90%;
            justify-content: center;

            button{
                position: absolute;
                margin-top: 70px;
                width: 90%;
                height: 50px;
            }
        }
      }

      .letter-image {
        position: absolute;
        display: none;

        img {
          height: auto;
          width: 100%;
        }
      }

      #left-image {
        margin-left: 16px;
        height: 200px;
        width: 130px;
        top: 150px;
      }

      #right-image {
        margin-right: 16px;
        height: auto;
        width: 150px;
        top:0;
      }

      .footer {
        position: static;
        height: 40vh;
        // justify-content: center;

        .bottom-nav {
        display: none;
        }

        hr {
            display: none;
        }

        .bottom-socials-wrapper {
          height: 50px;
          width: 100%;
          top: 70%;

          .socials {
            width: 100%;
            justify-content: center;

            .social {
              margin: 5px;
            }
          }
        }

        .copyright-wrapper {
            position: absolute;
          height: 100px;
          width: 100%;
          top: 80%;


          display:flex;

          #copyright{
            position: static;
          }

          #sponsor{
            margin: auto;
            margin-left: 50px;
            position: absolute;
            text-align: center;
            margin-top: 25px;
          }
        }
      }
    }
  }