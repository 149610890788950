.IntNavbar {
    width: 100%;
    height: 40px;
    display: flex;
    background: #161616;
    padding: 0 20px; /* Add padding for spacing */
    color: #fcfcfc;
    position: fixed;

    .logo{
        height: 36px;
        width: 36px;
        margin-left: 24px;

        img{
            height: 100%;
        }
    }

    h2{
        font-size: 16px;
        font-weight: 400;
        margin: auto;
        margin-left: 24px;
        
    }

    .CB{
        position: absolute;
        right: 150px;
        margin-top: 8px;
        font-size: 18px;
        text-decoration: none;
        color: #fcfcfc;

        &:hover{
            color:  #D9A800;
            font-weight: 600;
          }
    }
  
    button{
      color: #fcfcfc;
      font-family: Roboto;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      border: none;
      margin-top: 4px;
      margin-left: auto; /* Push the button and link to the right */
      background-color: #161616;
      margin-right: 5px;

      &:hover{
        color:  #D9A800;
        font-weight: 600;
      }
    }

    .profileImage{
        border-radius: 50px;
        -webkit-border-radius: 50px;
        -moz-border-radius: 50px;
        -ms-border-radius: 50px;
        -o-border-radius: 50px;

        height: 34px;
        width: 34px;
}

     // Media Query for Mobile Devices
  @media (max-width: 767px) {

    .logo{
        margin-left: 8px;
    }

    h2{
        font-size: 11px;
        margin-left: 12px;
    }

    .CB{
        font-size: 16px;
        right: 150px;
        top:1px
    }

    button{
        font-size: 16px;
    }

  }

  }
  