.bloglogin-area{

    // height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center items horizontally */
  justify-content: center; /* Center items vertically */
  position: relative;
  color: #FFFDF9;

    
    // background-image: url('./Images/Black-twist-pen-teacup.jpg');
    background: linear-gradient(rgba(13, 13, 13, 0.4), rgba(13,13 , 13, 0.7)),url('Images/Black.jpg');
    background-position: center;
    background-size: cover;
    display: flex;

    h1{
        text-align: center;
        margin-top: 70px;
    }

    p{
        text-align: center;
        margin-top: 30px;
    }

    button{
        margin-top: 40px;

        background-color: #4285f4; /* Google Blue */
        color: #ffffff; /* White text */
        border: none;
        padding: 10px 20px;
        font-size: 16px;
        border-radius: 4px;
        cursor: pointer;
        transition: background-color 0.3s ease;
      
        &:hover {
          background-color: #357ae8; /* Darker Google Blue on hover */
          cursor: pointer;
        }
      
        &:focus {
          outline: none; /* Remove the default focus outline */
        }
    }

    .email-password-signin{
      margin-top: 24px;
      display: flex;
      flex-direction: column; /* Changed this line to make the input fields stack vertically */
      align-items: center; /* Added this line to center the input fields horizontally */

  
      input{
        width: 409px;
        height: 48px;
        // flex-shrink: 0;
        border-radius: 5px;
        background: var(--rgb-252252252, #FCFCFC);
        border: none;
        margin: 10px auto; /* Adjusted margin for spacing between input fields */
        padding-left: 16px; /* Added padding to move text 16px from the left */
        outline: none; /* Removed the default focus outline */

        &::placeholder {
          /* Adjusted placeholder styling */
          color: #999;
          font-size: 16px;
          margin-left: 16px;
        }
      }

      button{
        background-color: #D9A800;
        
      }

      .password-input{
        width: 410px;
        input{

        }
        span{
          margin-left: -48px;
          color: #D9A800;
          cursor: pointer;
        }
      }
    }

    .error-message{
      color: rgba(255, 0, 0, 0.825);
    }

    .internal-text{
        margin-top: 84px;
        font-size: 12px;
    }

    .back-text{
        margin-top: 20px;
        font-size: 14px;
        color:  #D9A800;
        text-decoration: none;
        
    }

}