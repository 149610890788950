.createblog-area {
  background-color: #F5F5F5;
  min-height: 100vh;
}
.createblog-area .cb-Container {
  min-height: 100vh;
  width: 100%;
  display: flex;
}
.createblog-area .cb-Container .title {
  display: flex;
  width: 100%;
  height: 85px;
  position: absolute;
  background-color: #FFFDF9;
  border-bottom: 2px #161616 solid;
}
.createblog-area .cb-Container .title label {
  color: #161616;
  margin-left: 16px;
  font-size: 24px;
  font-weight: 500;
  border-style: none;
}
.createblog-area .cb-Container .title input {
  height: 50px;
  width: 600px;
  position: absolute;
  margin-top: 30px;
  left: 24px;
  border: none;
  background-color: #FFFDF9;
  border-style: none;
}
.createblog-area .cb-Container .title input:focus {
  outline: none;
  /* Remove the default focus border */
}
.createblog-area .cb-Container .title button {
  right: 16px;
  position: absolute;
  width: 150px;
  height: 45px;
  margin-top: 30px;
  background-color: #D9A800;
  cursor: pointer;
}
.createblog-area .cb-Container .title button:hover {
  background-color: #FFFDF9;
}
.createblog-area .cb-Container .post {
  margin-top: 80px;
  background-color: #F5F5F5;
  height: 100vh;
  width: 100%;
  display: flex;
}
.createblog-area .cb-Container .post .uploadimagearea {
  background-color: #D9A800;
  height: 400px;
  width: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
}
.createblog-area .cb-Container .post .uploadimagearea label {
  text-align: center;
  color: 161616;
  position: absolute;
  font-size: 24;
  font-weight: 400;
  top: 16px;
}
.createblog-area .cb-Container .post .uploadimagearea input {
  text-align: center;
  position: absolute;
  width: 100px;
  top: 48px;
}
.createblog-area .cb-Container .post .uploadimagearea .imageUpload {
  width: 100%;
}
.createblog-area .cb-Container .post .uploadimagearea .imageUpload img {
  object-fit: cover;
  object-position: top;
  width: 100%;
  height: 400px;
}
.createblog-area .cb-Container .post textarea {
  position: absolute;
  margin-top: 150px;
  margin-left: 20.5%;
  padding: 10px;
  background-color: #FFFDF9;
  width: 59%;
  min-height: 500px;
  outline: none;
  border: none;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: height 0.3s ease;
}
@media (max-width: 767px) {
  .createblog-area .cb-Container {
    overflow: hidden;
  }
  .createblog-area .cb-Container .title input {
    width: 64%;
  }
  .createblog-area .cb-Container .title button {
    width: 110px;
  }
  .createblog-area .cb-Container .post textarea {
    width: 90%;
    margin-left: 16px;
    font-size: 14px;
    min-height: 600px;
  }
}

