.landing {
  height: 100vh;
  width: 100%;
  display: flex;
  background-color: black;
}
.landing .desktop_landing {
  height: 100%;
  width: 100%;
}
.landing .mobile_landing {
  display: none;
}

@media (max-width: 768px) {
  .landing {
    position: static;
    flex-direction: column;
    background-color: #FCFCFC;
    height: auto;
  }
  .landing video {
    height: auto;
  }
  .landing .desktop_landing {
    display: none;
  }
  .landing .mobile_landing {
    display: block;
  }
}

