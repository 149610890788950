.postFullview{
    min-height: 300vh;
    width: 100%;
    background: var(--rgb-255253249, #FFFDF9);
    font-family: Roboto, Opensans;
    position: relative;
    // background-color: purple;
    
   

    .postAd{
        // background-color: red;
        top: 0;
        min-height: 250vh;
        
        .ads-wrapper{
            // background-color: red;
            width: 400px;
            min-height: 100vh;
            right: 120px;
            margin:auto;
            position: absolute;
            z-index: 500;
            margin-top: 120px;

            justify-content: center;
            align-items: center;
            // display: block;

            .ads{
            // position: relative;
            margin: auto;
            width: 95%;
            height: auto;
            border-radius: 5px;
            //    background-color: #D9A800;
            -webkit-border-radius: 5px;
            -moz-border-radius: 5px;
            -ms-border-radius: 5px;
            -o-border-radius: 5px;
            //    box-shadow: 0px 16px 18px 0px rgba(0, 0, 0, 0.16);

            display: flex;
            flex-direction: column;
            gap: 20px;

                .ad{
                    // position: relative;
                    // margin-top:16px;

                    position: relative;
                    width: 100%;
                    height: 680px; /* Adjust this height as needed */
                    overflow: hidden;
                    background: #fff;
                    border: 1px solid #ddd;
                    border-radius: 8px;
                    box-shadow: 0px 16px 18px 0px rgba(0, 0, 0, 0.16);
                    

                    img{
                        // height: 600px;
                        // width: 300px;
                        // margin-top: 8px;
                        padding: 8px;

                        width: 100%;
                        height: 100%;
                        object-fit: fill; /* Ensures the image covers the whole container without distortion */
                                        
                    }
                    
                }
            }
            
        }

        .PostImageBG{
            height: 95vh;
            width: 100%;
            position: absolute; // Ensure relative positioning for absolute positioning inside
          


            .gradient-overlay {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                // background: linear-gradient(to right, rgba(0, 0, 0, 0.5), transparent);
                z-index: 2;
            }

            img{
                height: 100%;
                width: 100%;
                object-fit:fit;
                //Maintainaspectratioandcoverthecontainerobject-position: center;
                //Aligntheimagetothetopposition: relative;
                filter: blur(10px) ;
                /*Adjusttheblurvalueasneeded*/-webkit-filter: blur(0 10px) ;
                -webkit-filter: blur(10px) ;
            }

            h1{
                position: relative;
                color: #D9A800;
                font-size: 50px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                margin-top: -661px;
                margin-left: 50px;
                z-index: 2;
                width: 1000px;

            
            }

            h3{
                position: relative;
                color: #D9D9D9;
                font-size: 30px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                // margin-top: -530px;
                margin-left: 50px;
                z-index: 2;
            
            }
        }

        

        .ThePost {
            border-radius:5px ;
           
            width: 60%;
            flex-direction: column;
            
            margin-left: 150px;
            top: 120px;
            // margin:auto;
            margin-bottom: 24px;
            min-height: 500px;
            flex-shrink: 0;
            background: #FFFFFF;
            box-shadow: 0px 16px 18px 0px rgba(0, 0, 0, 0.16);
            position: relative;
            padding: 36px 0 16px 0;
            z-index: 2;
            
    


        
            .titlearea{
                min-height: none;
                margin-bottom: 24px;

                h1{
                    margin-left: 55px;
                    color: #D9A800;
                    font-weight: bold;
                }

                h5{
                    margin-top: 10px;
                    margin-left: 55px;
                    font-size: 16px;
                    color: #707070;
                    font-weight: 400;
                }
            }

            .imagearea{

                width: 100%;
                display: flex;
                margin-bottom: 32px;

                img{
                    position: absolute;
                    width: 95%;
                    justify-content: center;
                    justify-items: center;
                    object-fit:fit; // Maintain aspect ratio and cover the container
                    object-position: center; // Align the image to the top
                    position: relative;
                    margin: auto;
        
                }
            }
            
        
            p {
            width: 90%;
            flex-shrink: 0;
            color: #262626;
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-left: 55px;
            padding: 5px ;
            line-height: normal;
            letter-spacing: 1.5px;
            margin-top: 36px;


                a {
                    color: #D9A800;
                    
                    overflow: hidden;
                  
                    text-wrap: wrap;
                    width: 100%;
                    font-size: smaller;

                    display:inline-flex;
                }
            }

            .share-button{
               margin-right: 16px;
                width: 150px;
                height: 45px;
                margin-top: 30px;
                border: none;
                cursor: pointer;


                &:hover{
                    background-color: #D9A800;
                  }
            
            }

            .postareafooter{
                height: 40px;
                width: 100%;
                margin-top: 32px;
                margin-bottom: 0px;
                display: flex;

                button {

                    right: 24px;
                    position: absolute;
                    margin: auto;
                margin-top: 5px;

                    display: inline-block;
                    outline: none;
                    height: 35px;
                    width: 150px;
                    border: solid  #161616;
                
                    color: #161616;
                    font-size: 16px;
                    text-align: center;
                    margin: auto;
                    cursor: pointer;
                }
            
                button:hover{
                    background-color: #D9A800;
                    cursor: pointer;
                }
            
                button:active {
                    transform: translateY(1px);
                    -webkit-transform: translateY(1px);
                    -moz-transform: translateY(1px);
                    -ms-transform: translateY(1px);
                    -o-transform: translateY(1px);
                }
            }

        }
        

        .comments-area{
        
            // position: relative;
            margin-top:150px;
            bottom:auto;
            // background-color: green;
            height: auto;
            width: 60% ;
            margin-left: 120px;
        }

        .blogs-next-preview{
            height:auto;
            width:60%;
            // background-color: red;
            display: flex;

            margin-left: 150px;
            margin-top: 4%;
            margin-bottom: 2%;

            h5{
                color: #D9A800;
                margin-top: -32px;
               position: absolute;
            }

            #previous{
                margin-left:0;
            }

            #next{
                margin-right:0px;
                // position: relative;
            }

            .Bpreview{
                margin: auto;
                height:173px ;
                min-width: 45%;

               border: 1px #161616 solid;
    
                display: flex;
                font-family: 'Roboto';

                img{
                    height: 170px;
                    width: 200px;
                    object-fit: fit;
                    position: absolute;
                }
    
                h2{
                    color:#161616;
                    position: absolute;
                    margin-top: 24px;
                    margin-left: 224px;
                    font-size: 24px;
                    font-weight: 500;
                    text-wrap: wrap;
                    width: 270px;
                }
    
                a{
                    text-decoration: none;
                    color: #D9A800;
                    position: absolute;

                    margin-top: 120px;
                    margin-left: 224px;
                }
                
            }

        }
    }
    
    @media (max-width: 1440px) {

        .postAd {
            // background-color: red;

          .ads-wrapper {
            right: 4%;
          }

            .ThePost{
                width: 60% ;
                margin:auto;
                margin-left: 100px;
                min-height: 500px;
            
                .titlearea{
                    h1{
                    }
        
                    h5{
                    }
                }

                p{

                }
            }

            .comments-area{
                width: 80%;
                margin-left:-50px ;
            }

            .blogs-next-preview{

                width: 70%;
                // background-color: red;
                margin: auto;
                margin-top: 36px;
                margin-bottom: 32px;
                margin-left: 100px;


                .Bpreview{

                    img{
                    }

                    h5{
                    }

                    h2{
                        text-wrap: wrap;
                        width: 230px;
                        font-size: 22px;
                    }

                    a{
                    }
                }
            }
    
        }
    }

    @media (max-width: 767px) {

        
        .postAd {
            display: block;

          .ads-wrapper {
            display: none; // Hide ads in the initial position
          }

            .ThePost{
                width: 95% ;
                margin:auto;
                min-height: 500px;
            
                .titlearea{
                    h1{

                        width: 90%;
                        margin-left: 16px;

                    
                    }
        
                    h5{
                        margin-left: 16px;
                    }
                }

                p{
                    font-size: 16px;
                    margin: auto;
                }
            }

            .comments-area{
                width: 95% !important;
                margin-left: 0;
            }

            .blogs-next-preview{

                width: 80%;
                // background-color: red;
                margin: auto;
                margin-left: 16px;

                .Bpreview{
                    border: none;
                    border-left: 1px #161616 solid;
                    padding: 8px;

                    img{
                        display: none;
                    }

                    h5{
                        margin: auto;
                        // margin-left: 8px;
                        
                    }

                    h2{
                        margin: auto;
                        margin-top: 36px;
                        font-size: 14px;
                        text-wrap: wrap;
                        width: 100px;
                        // margin-left: 8px;
                    }

                    a{
                        margin: auto;
                        margin-top: 100px;
                        // margin-left: 8px;
                    }
                }
            }
      
      
            .mobile-ads {
                display: block;
                width: 90%;
                margin: 20px auto; // Center the ads under the comments section
                min-height: 100vh;
                position: relative;

                overflow: scroll;

                .ads {
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                
                    .ad {
                        width: 100%;
                        height: 80vh; /* Adjust the height as needed */
                        overflow: hidden;
                        background: #fff;
                        border: 1px solid #ddd;
                        border-radius: 8px;
                        box-shadow: 0px 16px 18px 0px rgba(0, 0, 0, 0.16);
                
                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: fit; /* Ensures the image covers the whole container without distortion */
                        }
                    }
                }
            }
        }
    }

}

.postloading{
    background-color: #D9A800;
    display: flex;
    height: 100vh;
    justify-content: center;

    img{
        margin: auto;
        height: 300px;
        width: 300px;
    }

    p{
        text-align: center;
        display: flex;
        justify-self: center;
        margin: auto;
        position: absolute; 
        top: 80%;

        .loader {
            border: 16px solid #f3f3f3;
            border-radius: 50%;
            border-top: 16px solid #D9A800;
            width: 24px;
            height:24px;
            -webkit-animation: spin 2s linear infinite;
            /*Safari*/animation: spin 2s linear infinite;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            -ms-border-radius: 50%;
            -o-border-radius: 50%;
        }
          
    }
}
      

