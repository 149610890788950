.contact_us{
    height: 75vh;
    width: 100%;
    background-color: #D9A800;
    // position: absolute;
    display: flex;


    .contacts-wrapper{
        height: 70%;
        width: 100%;
        // background-color:green;
        margin: auto;
        display: flex;

        .contact-details{
            height: 100%;
            width: 50%;
            // background-color: yellowgreen;
            left: 0;

            .detail{
                height: 100px;
                width: 500px;
                border-radius: 5px;
                background-color: #FFFDF9;
                -webkit-border-radius: 5px;
                -moz-border-radius: 5px;
                -ms-border-radius: 5px;
                -o-border-radius: 5px;

                box-shadow: 10px 10px 8px rgba($color: #161616, $alpha: .1);
                margin: auto;
                margin-top: 34px;
                display: flex;

                .contact-icon{
                    height: 40px;
                    width: 40px;
                    // background-color: aqua;
                    margin: auto;
                    margin-left: 50px;
                    border-radius: 50%;
                    -webkit-border-radius: 50%;
                    -moz-border-radius: 50%;
                    -ms-border-radius: 50%;
                    -o-border-radius: 50%;
                    display: flex;
                } 
                
                h4{
                    font-size: 22px;
                    font-weight: 500;
                    position: absolute;
                    margin-left: 130px;
                    margin-top: 22px;
                    color: #D9A800;
                }

                p{
                    font-size: 16px;
                    font-weight: 500;
                    position: absolute;
                    margin-left: 130px;
                    margin-top: 54px;
                    color: #161616;
                }
            }
        }

        .get-in-touch{
            height: 100%;
            width: 50%;
            // background-color:orange;
            right: 0;

            .GIT-content{
                // background-color: aqua;
                height: 140px;
                width: 90%;
                margin-right: 100px;
                margin-top: 20px;

                h3{
                    font-size: 36px;
                }

                p{
                    margin-top: 20px;
                }
            }

            form{
                height: 270px;
                width: 90%;
                // background-color:aquamarine;

                .inpute-box{
                    height: 50px;
                    width: 300px;
                    background-color: #FCFCFC;
                    border-radius: 5px;
                    -webkit-border-radius: 5px;
                    -moz-border-radius: 5px;
                    -ms-border-radius: 5px;
                    -o-border-radius: 5px;

                    border: none;
                    box-shadow: 10px 10px 8px -5px rgba($color: #161616, $alpha: .1);
                    border: none;
                    margin: auto;
                    outline: none;
                    font-family: sans-serif;
                }

                .inpute-box:focus {
                    border: none;
                }

                .inpute-box[type=email] {
                    padding: 12px 20px;
                    box-sizing: border-box;
                    -webkit-transition: 0.5s;
                    transition: 0.5s;
                    outline: none;
                }

                .inpute-box[type=name] {
                    padding: 12px 20px;
                    box-sizing: border-box;
                    -webkit-transition: 0.5s;
                    transition: 0.5s;
                    outline: none;
                }

                .inpute-box[type=text] {
                    padding: 12px 20px;
                    box-sizing: border-box;
                    -webkit-transition: 0.5s;
                    transition: 0.5s;
                    outline: none;
                    top: 5px;
                }

                #email{
                    margin-left: 20px;
                }

                #message{
                    margin-top: 20px;
                    width: 625px;
                    height: 100px;
                }

                button{
                    height: 55px;
                    width: 625px;
                    color: #FCFCFC;
                    font-weight: bold;
                    font-size: 16px;
                    border-radius: 5px;
                    -webkit-border-radius: 5px;
                    -moz-border-radius: 5px;
                    -ms-border-radius: 5px;
                    -o-border-radius: 5px;
                    border: 2px solid #FCFCFC;
                    margin-top: 10px;
                    background-color:#D9A800;
                }

                button:hover{
                    background-color: #FCFCFC;
                    color: #D9A800;
                    cursor: pointer;
                }
            }
        }
    }
}

  // Media Query for Mobile
@media (max-width: 768px) {
    .contact_us {
        // display: none;
        
    //   flex-direction: column;
        
      height: 125vh;
    //   width: 100% !important;
    //   overflow: hidden;

    //   display: flex;

      .contacts-wrapper{
            // background-color: aqua;
            width: 100%;
            height: 100%;
            display: flex;
            overflow-x: hidden;

            .contact-details{
                // background-color: red;
                position: absolute;
                width: 100%;
                height: auto;
                margin-top: 10%;

                .detail{
                    width: 95%;
                }
            }

            .get-in-touch{
                // background-color: green;
                position: absolute;
                width: 100%;
                height: 550px;
                display: flex;
                margin-top: 480px;

                .GIT-content{

                    justify-content: center;
                    width: 100%;
                    position: absolute;
                    // background-color: yellow;
                    top: 0;
                    
                    h3{
                        // width: 100%;
                        position: absolute;
                        left: 16px;
              
                    }

                    p{
                        position: absolute;
                        left: 16px;
                        right: 16px;
                        top: 40px;
                    }
                }

                form{
                    display: flex;
                    width: 100%;
                    height: 350px;
                    position: absolute;
                    bottom: 5%;
                    // background-color: pink;
                    justify-content: center;
                   

                    .inpute-box[type=name]{

                        position: absolute;
                        width: 95%;
                        top: 10px;
                    }

                    .inpute-box[type=email]{

                        width: 95%;
                        margin-left:0 !important;
                        position: absolute;
                        top: 80px;
                    }

                    .inpute-box[type=text]{
                        position: absolute;
                        width: 95% !important;
                        top: 130px;
                    }

                    button{
                        width: 95% !important;
               
                        position: absolute;
                        top: 260px;
                    }
                }
            }
        }

    }

}
