@import url('https://fonts.googleapis.com/css2?family=Hurricane&display=swap');

.BRLanding{
    // height: 90vh;

    width: 100%;
    // background-color: #FFFDF8;
    overflow: none;

    min-height: auto;
    // background-color: aqua;

    .BRLanding-hero{
        height: 250px;
        display: flex;
        height: 210px;
        width: 100%;

        background-color: #FFFDF8;
        background-image: url('./Images/banner_bg.svg');
        background-position: center;
        background-size: cover;
        display: flex;

        h3{
            color: #000;
            font-family: 'Hurricane';
            font-size: 105px;
            font-style: normal;
            font-weight: 400;
            line-height: 173px; /* 164.762% */
            text-align: center;
            margin: auto;
        }
        
    }

    .BR_area_wrapper{
        // height: 45vh;
        // height: 100vh;
        // min-height: none;
        width: 95%;
        // display: flex;
        overflow: auto;
        // white-space: nowrap;
        margin: auto;

    }

        // Media Query for Mobile Devices
        @media (max-width: 767px) {
            // height: 100vh;
            scroll-behavior: smooth;
            overflow: hidden;
            
            .BRLanding-hero{
                h3{
                    font-size: 114px;
                }
            }

            .BR_area_wrapper{
                margin: auto;

                .blogz{
    
                }
            }

        }
}