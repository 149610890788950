.about_us{
    height: 100vh;
    width: 100%;
    // position: absolute;
    display: flex;
    // background-color: gray;
    background-image: url("./Images/About-Us-cover.png");
    background-position: center;
    background-size: cover;

    // iframe{
    //     position: absolute;
    //     height: 100%;
    //     width: 100%;
    //     background-color:#161616;
    //     display: flex;
    //     margin: auto;
    //     border: none;
    // }

    .left_block{
        position: absolute;
        display: flex;
        left: 150px;
        height: 750px;
        width: 700px;
        // background-color:rgba($color: #464646, $alpha: .8);
        background: rgb(70,70,70);
        background: linear-gradient(0deg, rgba(70,70,70,0) 10%, rgba(70,70,70,1) 34%, rgba(255,255,255,0) 87%);

        h3{
            font-size: 32px;
            font-weight: bold;
            top: 200px;
            left: 80px;
            color:  #FFFDF9;
            position: absolute;
        }

        p{
            position: absolute;
            bottom: 150px;
            left: 80px;
            right: 100px;
            font-size: 16px;
            font-weight: 500;
            color: #FFFDF9;
            width:550px;
            margin-right:100px;
        }
    }

    .right_block{
        height: 600px;
        width: 300px;
        display: flex;
        position: absolute;
        right: 100px;
        margin-top: 250px;
        // background-color:rgba($color:  #D9A800, $alpha: .4);

        // background: rgb(70,70,70);
        // background: linear-gradient(0deg, rgba(70,70,70,0) 10%, rgba(70,70,70,1) 34%, rgba(255,255,255,0) 87%);
   
        .profile_image{
            // position: absolute;
            height: 250px;
            width: 250px;
            border-radius: 50%;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            -ms-border-radius: 50%;
            -o-border-radius: 50%;

            background-color: #FFFDF9;
            margin: auto;
            margin-top: 100px;
            display: flex;

            img{
                height: 180px;
                width: 180px;
                margin: auto;
                
            }
        }
    }
}

 // Media Query for Mobile Devices
@media (max-width: 767px){
    .about_us {
      flex-direction: column;
      width: 100%;
      overflow: hidden;
      margin: auto;

      background-image: url("./Images/About-Us-cover-For-Mobile.png");
      background-position: center;
      background-size: cover;
      background-color: #464545;

  
    //   .left_block,
    //   .right_block {
    //     position: absolute;
    //     width: 100%;
    //     margin: auto;
    //   }
  
      .left_block {
        // display: none;
        left: 0;
        margin-top:75%;
        height: 65vh;
        width: 100%;
        margin-bottom: 0;
        background: rgb(70,70,70);
        background: linear-gradient(180deg, rgba(70,70,70,0.15872286414565828) 0%, rgba(70,70,70,0) 79%, rgba(255,255,255,0) 100%);
  
        h3 {
          top: 50px;
          left: 24px;
          color: #D9A800;
          
        }
  
        p {
          bottom: 0;
          left: 24px;
        //   right: 50px;
          width: 80%;
          margin-right: 0;
          font-size: 1rem;
          
        }
      }
  
      .right_block {
        display: none;
        // right: 0;
        // margin-top: 50px;
  
        // .profile_image {
        //   height: 180px;
        //   width: 180px;
        //   margin-top: 0;
        //   margin-bottom: 30px;
        // }
      }
    }
}