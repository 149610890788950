.postFullview {
  min-height: 300vh;
  width: 100%;
  background: var(--rgb-255253249, #FFFDF9);
  font-family: Roboto, Opensans;
  position: relative;
}
.postFullview .postAd {
  top: 0;
  min-height: 250vh;
}
.postFullview .postAd .ads-wrapper {
  width: 400px;
  min-height: 100vh;
  right: 120px;
  margin: auto;
  position: absolute;
  z-index: 500;
  margin-top: 120px;
  justify-content: center;
  align-items: center;
}
.postFullview .postAd .ads-wrapper .ads {
  margin: auto;
  width: 95%;
  height: auto;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.postFullview .postAd .ads-wrapper .ads .ad {
  position: relative;
  width: 100%;
  height: 680px;
  /* Adjust this height as needed */
  overflow: hidden;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0px 16px 18px 0px rgba(0, 0, 0, 0.16);
}
.postFullview .postAd .ads-wrapper .ads .ad img {
  padding: 8px;
  width: 100%;
  height: 100%;
  object-fit: fill;
  /* Ensures the image covers the whole container without distortion */
}
.postFullview .postAd .PostImageBG {
  height: 95vh;
  width: 100%;
  position: absolute;
}
.postFullview .postAd .PostImageBG .gradient-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}
.postFullview .postAd .PostImageBG img {
  height: 100%;
  width: 100%;
  object-fit: fit;
  filter: blur(10px);
  /*Adjusttheblurvalueasneeded*/
  -webkit-filter: blur(0 10px);
  -webkit-filter: blur(10px);
}
.postFullview .postAd .PostImageBG h1 {
  position: relative;
  color: #D9A800;
  font-size: 50px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: -661px;
  margin-left: 50px;
  z-index: 2;
  width: 1000px;
}
.postFullview .postAd .PostImageBG h3 {
  position: relative;
  color: #D9D9D9;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 50px;
  z-index: 2;
}
.postFullview .postAd .ThePost {
  border-radius: 5px;
  width: 60%;
  flex-direction: column;
  margin-left: 150px;
  top: 120px;
  margin-bottom: 24px;
  min-height: 500px;
  flex-shrink: 0;
  background: #FFFFFF;
  box-shadow: 0px 16px 18px 0px rgba(0, 0, 0, 0.16);
  position: relative;
  padding: 36px 0 16px 0;
  z-index: 2;
}
.postFullview .postAd .ThePost .titlearea {
  min-height: none;
  margin-bottom: 24px;
}
.postFullview .postAd .ThePost .titlearea h1 {
  margin-left: 55px;
  color: #D9A800;
  font-weight: bold;
}
.postFullview .postAd .ThePost .titlearea h5 {
  margin-top: 10px;
  margin-left: 55px;
  font-size: 16px;
  color: #707070;
  font-weight: 400;
}
.postFullview .postAd .ThePost .imagearea {
  width: 100%;
  display: flex;
  margin-bottom: 32px;
}
.postFullview .postAd .ThePost .imagearea img {
  position: absolute;
  width: 95%;
  justify-content: center;
  justify-items: center;
  object-fit: fit;
  object-position: center;
  position: relative;
  margin: auto;
}
.postFullview .postAd .ThePost p {
  width: 90%;
  flex-shrink: 0;
  color: #262626;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 55px;
  padding: 5px;
  line-height: normal;
  letter-spacing: 1.5px;
  margin-top: 36px;
}
.postFullview .postAd .ThePost p a {
  color: #D9A800;
  overflow: hidden;
  text-wrap: wrap;
  width: 100%;
  font-size: smaller;
  display: inline-flex;
}
.postFullview .postAd .ThePost .share-button {
  margin-right: 16px;
  width: 150px;
  height: 45px;
  margin-top: 30px;
  border: none;
  cursor: pointer;
}
.postFullview .postAd .ThePost .share-button:hover {
  background-color: #D9A800;
}
.postFullview .postAd .ThePost .postareafooter {
  height: 40px;
  width: 100%;
  margin-top: 32px;
  margin-bottom: 0px;
  display: flex;
}
.postFullview .postAd .ThePost .postareafooter button {
  right: 24px;
  position: absolute;
  margin: auto;
  margin-top: 5px;
  display: inline-block;
  outline: none;
  height: 35px;
  width: 150px;
  border: solid #161616;
  color: #161616;
  font-size: 16px;
  text-align: center;
  margin: auto;
  cursor: pointer;
}
.postFullview .postAd .ThePost .postareafooter button:hover {
  background-color: #D9A800;
  cursor: pointer;
}
.postFullview .postAd .ThePost .postareafooter button:active {
  transform: translateY(1px);
  -webkit-transform: translateY(1px);
  -moz-transform: translateY(1px);
  -ms-transform: translateY(1px);
  -o-transform: translateY(1px);
}
.postFullview .postAd .comments-area {
  margin-top: 150px;
  bottom: auto;
  height: auto;
  width: 60%;
  margin-left: 120px;
}
.postFullview .postAd .blogs-next-preview {
  height: auto;
  width: 60%;
  display: flex;
  margin-left: 150px;
  margin-top: 4%;
  margin-bottom: 2%;
}
.postFullview .postAd .blogs-next-preview h5 {
  color: #D9A800;
  margin-top: -32px;
  position: absolute;
}
.postFullview .postAd .blogs-next-preview #previous {
  margin-left: 0;
}
.postFullview .postAd .blogs-next-preview #next {
  margin-right: 0px;
}
.postFullview .postAd .blogs-next-preview .Bpreview {
  margin: auto;
  height: 173px;
  min-width: 45%;
  border: 1px #161616 solid;
  display: flex;
  font-family: "Roboto";
}
.postFullview .postAd .blogs-next-preview .Bpreview img {
  height: 170px;
  width: 200px;
  object-fit: fit;
  position: absolute;
}
.postFullview .postAd .blogs-next-preview .Bpreview h2 {
  color: #161616;
  position: absolute;
  margin-top: 24px;
  margin-left: 224px;
  font-size: 24px;
  font-weight: 500;
  text-wrap: wrap;
  width: 270px;
}
.postFullview .postAd .blogs-next-preview .Bpreview a {
  text-decoration: none;
  color: #D9A800;
  position: absolute;
  margin-top: 120px;
  margin-left: 224px;
}
@media (max-width: 1440px) {
  .postFullview .postAd .ads-wrapper {
    right: 4%;
  }
  .postFullview .postAd .ThePost {
    width: 60%;
    margin: auto;
    margin-left: 100px;
    min-height: 500px;
  }
  .postFullview .postAd .comments-area {
    width: 80%;
    margin-left: -50px;
  }
  .postFullview .postAd .blogs-next-preview {
    width: 70%;
    margin: auto;
    margin-top: 36px;
    margin-bottom: 32px;
    margin-left: 100px;
  }
  .postFullview .postAd .blogs-next-preview .Bpreview h2 {
    text-wrap: wrap;
    width: 230px;
    font-size: 22px;
  }
}
@media (max-width: 767px) {
  .postFullview .postAd {
    display: block;
  }
  .postFullview .postAd .ads-wrapper {
    display: none;
  }
  .postFullview .postAd .ThePost {
    width: 95%;
    margin: auto;
    min-height: 500px;
  }
  .postFullview .postAd .ThePost .titlearea h1 {
    width: 90%;
    margin-left: 16px;
  }
  .postFullview .postAd .ThePost .titlearea h5 {
    margin-left: 16px;
  }
  .postFullview .postAd .ThePost p {
    font-size: 16px;
    margin: auto;
  }
  .postFullview .postAd .comments-area {
    width: 95% !important;
    margin-left: 0;
  }
  .postFullview .postAd .blogs-next-preview {
    width: 80%;
    margin: auto;
    margin-left: 16px;
  }
  .postFullview .postAd .blogs-next-preview .Bpreview {
    border: none;
    border-left: 1px #161616 solid;
    padding: 8px;
  }
  .postFullview .postAd .blogs-next-preview .Bpreview img {
    display: none;
  }
  .postFullview .postAd .blogs-next-preview .Bpreview h5 {
    margin: auto;
  }
  .postFullview .postAd .blogs-next-preview .Bpreview h2 {
    margin: auto;
    margin-top: 36px;
    font-size: 14px;
    text-wrap: wrap;
    width: 100px;
  }
  .postFullview .postAd .blogs-next-preview .Bpreview a {
    margin: auto;
    margin-top: 100px;
  }
  .postFullview .postAd .mobile-ads {
    display: block;
    width: 90%;
    margin: 20px auto;
    min-height: 100vh;
    position: relative;
    overflow: scroll;
  }
  .postFullview .postAd .mobile-ads .ads {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .postFullview .postAd .mobile-ads .ads .ad {
    width: 100%;
    height: 80vh;
    /* Adjust the height as needed */
    overflow: hidden;
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0px 16px 18px 0px rgba(0, 0, 0, 0.16);
  }
  .postFullview .postAd .mobile-ads .ads .ad img {
    width: 100%;
    height: 100%;
    object-fit: fit;
    /* Ensures the image covers the whole container without distortion */
  }
}

.postloading {
  background-color: #D9A800;
  display: flex;
  height: 100vh;
  justify-content: center;
}
.postloading img {
  margin: auto;
  height: 300px;
  width: 300px;
}
.postloading p {
  text-align: center;
  display: flex;
  justify-self: center;
  margin: auto;
  position: absolute;
  top: 80%;
}
.postloading p .loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #D9A800;
  width: 24px;
  height: 24px;
  -webkit-animation: spin 2s linear infinite;
  /*Safari*/
  animation: spin 2s linear infinite;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

