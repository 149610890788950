.commentsSection {
  width: 75%;
  margin-left: 12.5%;
  padding: 20px;
}
.commentsSection h2 {
  color: #262626;
  font-family: Roboto;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 20px;
}
.commentsSection .comment {
  border-bottom: 1px solid #ccc;
  padding: 15px 0;
}
.commentsSection .comment p {
  color: #262626;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.commentsSection textarea {
  width: 100%;
  height: 100px;
  margin-bottom: 15px;
  padding: 10px;
  resize: none;
  flex-shrink: 0;
  outline: none;
  border: none;
}
.commentsSection textarea placeholder {
  color: #737373;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.commentsSection button {
  display: inline-block;
  outline: none;
  height: 40px;
  width: 150px;
  border: solid #161616;
  background-color: #D9A800;
  color: #161616;
  font-size: 16px;
  text-align: center;
  margin: auto;
  margin-bottom: 20px;
  cursor: pointer;
}
.commentsSection button:hover {
  background-color: #FCFCFC;
  cursor: pointer;
}
.commentsSection button:active {
  transform: translateY(1px);
  -webkit-transform: translateY(1px);
  -moz-transform: translateY(1px);
  -ms-transform: translateY(1px);
  -o-transform: translateY(1px);
}
.commentsSection .Pplsthought {
  margin-top: 50px;
}
@media (max-width: 767px) {
  .commentsSection {
    width: 95%;
    margin-left: 0px;
    margin: auto;
  }
  .commentsSection h2 {
    font-size: 24px;
    margin-left: 0px;
  }
}

