@import 'mixin.scss';


// Mags area
.block {

    min-height: 50vh;
    .year {
      @include Mags_layout;

    }
  
  }
  
//Create mag

.create-magazine-area {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f5f5f5;
    margin-top: 50px;
  
    .cm-container {
      width: 100%;
      // background-color: #fff;
      // padding: 20px;
      // margin-top: 20px;
    
      // box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      
      display: flex;
      
        .title {
            margin-bottom: 20px;

            display: flex;
            width: 100%;
            height: 85px;
            position: absolute;
            background-color:#FFFDF9;
            border-bottom: 2px #161616 solid;
            
            label {
                color: #161616;
                margin-left: 16px;
                font-size: 24px;
                font-weight: 500;
                border-style: none;
            }
            
              input{
                height: 50px;
                width: 600px;
                position: absolute;
                margin-top: 30px;
                left:24px;
                border: none;
                background-color:#FFFDF9;
                border-style: none
            }
            
    
            input[type="file"] {
            margin-top: 10px;
            }

            input:focus {
                outline: none; /* Remove the default focus border */
            }

            button{
              right: 36px;
              position: absolute;
              width: 150px;
              height: 45px;
              margin-top: 30px;
              background-color: #D9A800;
              cursor: pointer;
              
          }

          button:hover{
              background-color:#FFFDF9;
          }
        }

        .magazine-manager{
            display: inline-flex;
            position: relative;
            background-color:#FFFDF9;
            height: 100vh;
            margin-top: 85px;
            width: 100%;

            .cloud{
                position: relative;
                // background-color: pink;
                height: 100vh;
                margin-left: 48px;
                margin-top: 36px;
                width: 50%;

              .upload-wrapper{

                // background-color: green;
                width: 100%;
                height: 110px; 
                display: flex;

                label {
                  color: #D9A800;
                  margin: auto;
                  font-size: 16px;
                  font-weight: 500;
                  border-style: none;
                  margin-left: 80px;
                  margin-top: 24px;
                }

                p{
                  font-size: 12px;
                  color: #A8A5A5;
                  margin: auto;
                  // left: 80px;
                  top: 90px;
                  position: absolute;
                }


                .import-button{

                  margin-bottom: 20px;

                  display: flex;
                  width: 100%;
                  height: 85px;
                  position: absolute;
                  // background-color:purple;
                  border-bottom: none;
                  
       
                  input {
                    width: 62px;
                    height: 62px;
                    background-color: #D9A800;
                    margin-left: 0;
                   
                   
                    border-radius: 50px;
                    -webkit-border-radius: 50px;
                    -moz-border-radius: 50px;
                    -ms-border-radius: 50px;
                    -o-border-radius: 50px;

                  }

                //   input[type="text"] {
                //     height: 50px;
                //     width: 600px;
                //     position: absolute;
                //     margin-top: 30px;
                //     left:24px;
                //     border: none;
                //     background-color:#FFFDF9;
                //     border-style: none
                // }

                  input[type="file"] {
                    margin-top: 10px;
                    
                    
                  }

      
                }

              }

                h4{
                  color: #161616;
                  margin-top:24px;
                }

                hr{
                  width: 95%;
                  color:   #717171 ;
                }
                .posted-magazine{

                    // height: 100vh;
                    
                    width: 95%;
                    // background-color: red;
                    // position: relative;
                    display: flex;
                    flex-wrap: wrap;
                    margin-top: 36px;
                    
             

                    .magazine-item{
                      margin: auto;
                      margin-top: 24px;
                      // margin-left: 24px;
                      // background-color: yellow;
                      width: 100%;
                      position: relative;
                      display: inline-flex;
                      height: 100px;

                      &:hover{
                        border: .5px #b8b8b8 dotted;
                        padding: auto;
                      }
                      
                      // width: 45%;
                  
                        img{
                           height: 95px;
                           margin: auto;
                           margin-left: 16px;
                           box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
                           border-radius: 5px;
                           -webkit-border-radius: 3px;
                           -moz-border-radius: 5px;
                           -ms-border-radius: 5px;
                           -o-border-radius: 5px;
}

                        h4{
                            font-size: 16px;
                            color: #161616;
                            margin: auto;
                            margin-left: 0px;
                           width: 200px;
                            // background-color: #ff8a00;
                            text-wrap: wrap;
                        }

                        p{
                            font-size: 14px;
                            color:#707070;
                            margin: auto;
                        }

                        button{
                            // position: absolute;
                            bottom: 26px;
                            right: 0px;
                            background: transparent;
                            color: #D9A800;
                            border: none;
                            padding: 5px 10px;
                            cursor: pointer;
            
                            &:hover{
                                color: #161616;
                            }
                        }
                    }
                }
            }

            .magazine-preview{
              // background-color: rgb(56, 126, 56);
              height: 80vh;
              width: 50%;
              margin: auto;
              right: 100px;
              border-left: 1px  #D9D9D9 solid ;
              
              label {
                color: #D9A800;
                
                margin: auto;
                font-size: 16px;
                font-weight: 500;
                border-style: none;
                margin-left: 80px;
                margin-top: 24px;
              }

              p{
                font-size: 12px;
                color: #A8A5A5;
                margin: auto;
                text-align: center;
                // left: 80px;
                margin-top: 24px;
                // position: absolute;
              }
              // margin-top: 30px;
              // text-align: center;

              h3{
                margin: auto;
                text-align: center;
              }
              .navigation{

                // background-color: #2200ff;

                width: 100%;
                height: 100%;

                button{
                  width: 50px;
                  height: 50px;
                  border-radius: 50%;
                  margin-left: 24px;
                  position: absolute;
                  -webkit-border-radius: 50%;
                  -moz-border-radius: 50%;
                  -ms-border-radius: 50%;
                  -o-border-radius: 50%;
                }

                #left-btn{
                  //display: none;
                  transform:rotateZ(180deg) ;
                  margin-top:300px;
                  -webkit-transform:rotateZ(180deg) ;
                  -moz-transform:rotateZ(180deg) ;
                  -ms-transform:rotateZ(180deg) ;
                  -o-transform:rotateZ(180deg) ;
}
                #right-btn{
                  margin-right: 24px;
                  // margin: auto;
                  // display: none;
                  margin-top:358px;
                }
                
                .preview-container {
                  display: flex;
                  flex-wrap: wrap;
                  gap: 10px;
                  margin-bottom: 20prgb(255, 0, 162);
                  // background-color: #2200ff;
                  width: 100%;
                  height: 100%;

                  .preview-image {
                    margin: auto;
                    width: 1000px;
                    height: 69%;
                    object-fit: contain;
                    // border: 1px solid #ddd;
                    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
                    border-radius: 4px;
                    display: flex;
                  }

                  h2{
                    // display: none;
                    
                   margin: auto;
                   text-align: center;
                   margin-top: -24px;
                  //  margin-left: 264px;
                    width: 100%;
                  }
                }

              
              }
              
              img {
                width: 100%;
                max-width: 400px;
                height: auto;
                border: 1px solid #ddd;
                border-radius: 4px;
              }
          
              h2 {
                margin-top: 10px;
                font-size: 24px;
                color: #333;
              }
          
              .mag-title {
                font-size: 18px;
                color: #555;
              }
            }
        }
    
        .title-preview {
            margin-bottom: 20px;
    
            label {
            font-size: 16px;
            color: #333;
            }
    
            input {
            font-size: 18px;
            font-weight: bold;
            border: none;
            background-color: #f5f5f5;
            }
        }
  
     
  
      button {
        background-color: #ffaa00;
        color: #fff;
        border: none;
        border-radius: 4px;
        padding: 10px 20px;
        cursor: pointer;
        transition: background-color 0.3s;
  
        &:disabled {
          background-color: #ddd;
          cursor: not-allowed;
        }
  
        &:hover:enabled {
          background-color: #ff8a00;
        }
      }
    }
  }
  
  .footer-navigation {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
  }
  


  // Media Query for Mobile Devices
  @media (max-width: 767px) {


    .accordion-item{
        width: 100%;

        .accordion-header{
            margin-left: 24px;
        }

        .Year_2024{
            .mag_row{
                width: 100%;
                display: inline-flex;
                overflow-x: scroll;
            }

        }

        .Year_2023{
            .mag_row{
                width: 100%;
                display: inline-flex;
                overflow-x: scroll;
            }

        }
        
        .Year_2022{
            .mag_row{
                width: 100%;
                display: inline-flex;
                overflow-x: scroll;
            }
        }
    }
  }

