.MagazineViewer {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #FFFDF9;
}

.MagazineViewer .page {
    width: 600px;
    height: 400px;
    object-fit: contain;
    /* background-color: rgb(255, 145, 0); */
    background-color: #161616;
    /* color: #161616; */
}

.MagazineViewer .navHandle{
    position: absolute;
    top: 0;
}

.magnav{
    /* background-color: #D9A800; */
    width: 100%;
    height: 50px;
    position: absolute;
    top:0;
}

.magnav button {
    height: 40px;
	width: 150px;
	z-index: 5000;
	border-radius:5px;
	-webkit-border-radius:5px;
	-moz-border-radius:5px;
	-ms-border-radius:5px;
	-o-border-radius:5px;

	border: 2px solid #161616;
	/* background-color:#D9A800 ; */

	opacity: 40%;
}

.magnav button:hover{
    opacity: 100%;
	cursor: pointer;
    background-color:#D9A800 ;
}

.magnav #back {
  margin: auto;
  margin-left:24px;
  margin-top: 3px;
}

.magnav #share {
    /* margin: auto; */
    right:24px;
    margin-top: 3px;
    position: absolute;
    
  }

.page {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
}

.page-inner {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.page img {
    max-width: 100%;
    max-height: 100%;
}

.MagazineViewer {
    position: relative;
    margin: auto;
    overflow: hidden;
}

.page {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #ccc;
}

.page-inner {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cover-page {
    display:flex;
    margin: auto;
    justify-self: start;
    background-color: #f0f0f0; /* Example background color for cover pages */
    font-weight: bold; /* Example font style for cover pages */
}

.arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    z-index: 10;
    font-size: 18px;
    height: 20%;
    width: 2%;

}

.arrow:hover{
    background: #D9A800;
}

.left-arrow {
    left: 10px;
}

.right-arrow {
    right: 10px;
}


/* Media Query for Mobile Devices */


@media (max-width: 767px) {

    .MagazineViewer {
  
        /* background-color: #d81f1f; */
        height: 80vh;
    }
    
    .MagazineViewer .page {
        width: 100% !important;
        height: 100% !important;
        object-fit: contain;
        /* background-color: rgb(255, 145, 0); */
        background-color: transparent;
        /* color: #161616; */
    }

    .page {
        display: flex;
        justify-content: center;
        align-items: center;
        /* background-color: white; */
        height: 100vh;
        border: none;
     
    }

    .magnav #back {
        display: none;
      }
      
      .magnav #share {

          display: none;
        }

        .MagazineViewer .navHandle{
            display:block;
        }

        .cover-page {
            display:flex;
            margin: auto;
            justify-self: start;
            background-color: #f0f0f0; /* Example background color for cover pages */
            font-weight: bold; /* Example font style for cover pages */

        }
        

        .page img {
            max-width: 70%;
            max-height: 100%;
        }

        .arrow {
            position: absolute;
            top: 70%;
            transform: translateY(-50%);
            background: rgba(0, 0, 0, 0.1);
            color: white;
            border: none;
           
            cursor: pointer;
            z-index: 10;
            font-size: 18px;
        
            border-radius: 10px;
            -webkit-border-radius: 10px;
            -moz-border-radius: 10px;
            -ms-border-radius: 10px;
            -o-border-radius: 10px;
        }

        .left-arrow {
            left: 10px;
          
        }
        
        .right-arrow {
            right: 20px;
        }
      
 }

/* iphone se */
 @media (max-width: 375px) {

    .MagazineViewer {
  
        /* background-color: #d81f1f; */
        height: 80vh;
    }
    
    .MagazineViewer .page {
        width: 100% !important;
        height: 100% !important;
        object-fit: contain;
        /* background-color: rgb(255, 145, 0); */
        background-color: transparent;
        /* color: #161616; */
    }

    .page {
        display: flex;
        justify-content: center;
        align-items: center;
        /* background-color: white; */
        height: 100vh;
        border: none;
     
    }

    .magnav #back {
        display: none;
      }
      
      .magnav #share {

          display: none;
        }

        .MagazineViewer .navHandle{
            display:block;
        }

        .cover-page {
            display:flex;
            margin: auto;
            justify-self: start;
            background-color: #f0f0f0; /* Example background color for cover pages */
            font-weight: bold; /* Example font style for cover pages */

        }
        

        .page img {
            max-width: 65%;
            max-height: 100%;
        }

        .arrow {
            position: absolute;
            top: 70%;
            transform: translateY(-50%);
            background: rgba(0, 0, 0, 0.1);
            color: white;
            border: none;
           
            cursor: pointer;
            z-index: 10;
            font-size: 18px;
        
            border-radius: 10px;
            -webkit-border-radius: 10px;
            -moz-border-radius: 10px;
            -ms-border-radius: 10px;
            -o-border-radius: 10px;
        }

        .left-arrow {
            left: 10px;
          
        }
        
        .right-arrow {
            right: 20px;
        }
 }

 /* iphone xr */
 @media (max-width: 414px) {

    .MagazineViewer {
  
        /* background-color: #d81f1f; */
        height: 80vh;
    }
    
    .MagazineViewer .page {
        width: 100% !important;
        height: 100% !important;
        object-fit: contain;
        /* background-color: rgb(255, 145, 0); */
        background-color: transparent;
        /* color: #161616; */
    }

    .page {
        display: flex;
        justify-content: center;
        align-items: center;
        /* background-color: white; */
        height: 100vh;
        border: none;
     
    }

    .magnav #back {
        display: none;
      }
      
      .magnav #share {

          display: none;
        }

        .MagazineViewer .navHandle{
            display:block;
        }

        .cover-page {
            display:flex;
            margin: auto;
            justify-self: start;
            background-color: #f0f0f0; /* Example background color for cover pages */
            font-weight: bold; /* Example font style for cover pages */

        }
        

        .page img {
            max-width: 68%;
            max-height: 100%;
        }

        .arrow {
            position: absolute;
            top: 70%;
            transform: translateY(-50%);
            background: rgba(0, 0, 0, 0.1);
            color: white;
            border: none;
           
            cursor: pointer;
            z-index: 10;
            font-size: 18px;
        
            border-radius: 10px;
            -webkit-border-radius: 10px;
            -moz-border-radius: 10px;
            -ms-border-radius: 10px;
            -o-border-radius: 10px;
        }

        .left-arrow {
            left: 10px;
          
        }
        
        .right-arrow {
            right: 20px;
        }
 }

  /* iphone 12 Pro */
  @media (max-width: 390px) {

    .MagazineViewer {
  
        /* background-color: #d81f1f; */
        height: 80vh;
    }
    
    .MagazineViewer .page {
        width: 100% !important;
        height: 100% !important;
        object-fit: contain;
        /* background-color: rgb(255, 145, 0); */
        background-color: transparent;
        /* color: #161616; */
    }

    .page {
        display: flex;
        justify-content: center;
        align-items: center;
        /* background-color: white; */
        height: 100vh;
        border: none;
     
    }

    .magnav #back {
        display: none;
      }
      
      .magnav #share {

          display: none;
        }

        .MagazineViewer .navHandle{
            display:block;
        }

        .cover-page {
            display:flex;
            margin: auto;
            justify-self: start;
            background-color: #f0f0f0; /* Example background color for cover pages */
            font-weight: bold; /* Example font style for cover pages */

        }
        

        .page img {
            max-width: 65%;
            max-height: 100%;
        }

        .arrow {
            position: absolute;
            top: 70%;
            transform: translateY(-50%);
            background: rgba(0, 0, 0, 0.1);
            color: white;
            border: none;
           
            cursor: pointer;
            z-index: 10;
            font-size: 18px;
        
            border-radius: 10px;
            -webkit-border-radius: 10px;
            -moz-border-radius: 10px;
            -ms-border-radius: 10px;
            -o-border-radius: 10px;
        }

        .left-arrow {
            left: 10px;
          
        }
        
        .right-arrow {
            right: 20px;
        }
 }


   /* iphone 14 Pro Max*/
   @media (max-width: 430px) {

    .MagazineViewer {
  
        /* background-color: #d81f1f; */
        height: 70vh;
    }
    
    .MagazineViewer .page {
        width: 100% !important;
        height: 100% !important;
        object-fit: contain;
        /* background-color: rgb(255, 145, 0); */
        background-color: transparent;
        /* color: #161616; */
    }

    .page {
        display: flex;
        justify-content: center;
        align-items: center;
        /* background-color: white; */
        height: 100vh;
        border: none;
     
    }

    .magnav #back {
        display: none;
      }
      
      .magnav #share {

          display: none;
        }

        .MagazineViewer .navHandle{
            display:block;
        }

        .cover-page {
            display:flex;
            margin: auto;
            justify-self: start;
            background-color: #f0f0f0; /* Example background color for cover pages */
            font-weight: bold; /* Example font style for cover pages */

        }
        

        .page img {
            max-width: 71.5%;
            max-height: 100%;
        }

        .arrow {
            position: absolute;
            top: 70%;
            transform: translateY(-50%);
            background: rgba(0, 0, 0, 0.1);
            color: white;
            border: none;
           
            cursor: pointer;
            z-index: 10;
            font-size: 18px;
        
            border-radius: 10px;
            -webkit-border-radius: 10px;
            -moz-border-radius: 10px;
            -ms-border-radius: 10px;
            -o-border-radius: 10px;
        }

        .left-arrow {
            left: 10px;
          
        }
        
        .right-arrow {
            right: 20px;
        }
 }


 /* Pixel 7 Pro*/
    @media (max-width: 412px) {

        .MagazineViewer {
      
            /* background-color: #d81f1f; */
            height: 70vh;
        }
        
        .MagazineViewer .page {
            width: 100% !important;
            height: 100% !important;
            object-fit: contain;
            /* background-color: rgb(255, 145, 0); */
            background-color: transparent;
            /* color: #161616; */
        }
    
        .page {
            display: flex;
            justify-content: center;
            align-items: center;
            /* background-color: white; */
            height: 100vh;
            border: none;
         
        }
    
        .magnav #back {
            display: none;
          }
          
          .magnav #share {
    
              display: none;
            }
    
            .MagazineViewer .navHandle{
                display:block;
            }
    
            .cover-page {
                display:flex;
                margin: auto;
                justify-self: start;
                background-color: #f0f0f0; /* Example background color for cover pages */
                font-weight: bold; /* Example font style for cover pages */
    
            }
            
    
            .page img {
                max-width: 72%;
                max-height: 100%;
            }
    
            .arrow {
                position: absolute;
                top: 70%;
                transform: translateY(-50%);
                background: rgba(0, 0, 0, 0.1);
                color: white;
                border: none;
               
                cursor: pointer;
                z-index: 10;
                font-size: 18px;
            
                border-radius: 10px;
                -webkit-border-radius: 10px;
                -moz-border-radius: 10px;
                -ms-border-radius: 10px;
                -o-border-radius: 10px;
            }
    
            .left-arrow {
                left: 10px;
              
            }
            
            .right-arrow {
                right: 20px;
            }
}

 /* samsung galaxy s8+ */
 @media (max-width: 360px) {

    .MagazineViewer {
  
        /* background-color: #d81f1f; */
        height: 100vh;
    }
    
    .MagazineViewer .page {
        width: 100% !important;
        height: 100% !important;
        object-fit: contain;
        /* background-color: rgb(255, 145, 0); */
        background-color: transparent;
        /* color: #161616; */
    }

    .page {
        display: flex;
        justify-content: center;
        align-items: center;
        /* background-color: white; */
        height: 100vh;
        border: none;
     
    }

    .magnav #back {
        display: none;
      }
      
      .magnav #share {

          display: none;
        }

        .MagazineViewer .navHandle{
            display:block;
        }

        .cover-page {
            display:flex;
            margin: auto;
            justify-self: start;
            background-color: #f0f0f0; /* Example background color for cover pages */
            font-weight: bold; /* Example font style for cover pages */

        }
        

        .page img {
            max-width: 65%;
            max-height: 100%;
        }

        .arrow {
            position: absolute;
            top: 70%;
            transform: translateY(-50%);
            background: rgba(0, 0, 0, 0.1);
            color: white;
            border: none;
           
            cursor: pointer;
            z-index: 10;
            font-size: 18px;
        
            border-radius: 10px;
            -webkit-border-radius: 10px;
            -moz-border-radius: 10px;
            -ms-border-radius: 10px;
            -o-border-radius: 10px;
        }

        .left-arrow {
            left: 10px;
          
        }
        
        .right-arrow {
            right: 20px;
        }
}
    