.block {
  min-height: 50vh;
}
.block .year {
  margin-top: 36px;
}
.block .year .mag_row {
  width: 90%;
  height: auto;
  margin: auto;
  margin-top: 32px;
  margin-top: 5%;
}
.block .year .mag_row .mag {
  padding: 4px;
  cursor: pointer;
}
.block .year .mag_row .mag img {
  width: 208px;
  height: 282.35px;
  flex-shrink: 0;
  border-radius: 5px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}
.block .year .mag_row .mag h4 {
  margin-top: 10px;
  color: #262626;
  font-family: "Roboto";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-wrap: wrap;
  width: 208px;
}
.block .year .mag_row .mag h5 {
  color: #707070;
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.block .year .mag_row .mag img {
  transition: all 0.2s ease-in-out;
}
.block .year .mag_row .mag:hover img {
  transform: scale(1.1);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.block .year .mag_row .autoMag h2 {
  color: #D9D9D9;
  font-size: 36px;
  font-family: "Roboto";
  font-weight: 400;
  word-wrap: break-word;
  cursor: pointer;
  margin-bottom: 64px;
}
.block .year .mag_row .autoMag h3 {
  color: #D9A800;
  margin-top: 24px;
  margin-bottom: 24px;
}
.block .year .mag_row .autoMag .magazineTiles {
  display: flex;
  flex-direction: row;
  height: auto;
  margin: auto;
  width: 100%;
  flex-wrap: wrap;
  gap: 30px;
  margin-bottom: 3%;
}
.block .year .row_two {
  margin-top: 52px;
}

.create-magazine-area {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f5f5f5;
  margin-top: 50px;
}
.create-magazine-area .cm-container {
  width: 100%;
  display: flex;
}
.create-magazine-area .cm-container .title {
  margin-bottom: 20px;
  display: flex;
  width: 100%;
  height: 85px;
  position: absolute;
  background-color: #FFFDF9;
  border-bottom: 2px #161616 solid;
}
.create-magazine-area .cm-container .title label {
  color: #161616;
  margin-left: 16px;
  font-size: 24px;
  font-weight: 500;
  border-style: none;
}
.create-magazine-area .cm-container .title input {
  height: 50px;
  width: 600px;
  position: absolute;
  margin-top: 30px;
  left: 24px;
  border: none;
  background-color: #FFFDF9;
  border-style: none;
}
.create-magazine-area .cm-container .title input[type=file] {
  margin-top: 10px;
}
.create-magazine-area .cm-container .title input:focus {
  outline: none;
  /* Remove the default focus border */
}
.create-magazine-area .cm-container .title button {
  right: 36px;
  position: absolute;
  width: 150px;
  height: 45px;
  margin-top: 30px;
  background-color: #D9A800;
  cursor: pointer;
}
.create-magazine-area .cm-container .title button:hover {
  background-color: #FFFDF9;
}
.create-magazine-area .cm-container .magazine-manager {
  display: inline-flex;
  position: relative;
  background-color: #FFFDF9;
  height: 100vh;
  margin-top: 85px;
  width: 100%;
}
.create-magazine-area .cm-container .magazine-manager .cloud {
  position: relative;
  height: 100vh;
  margin-left: 48px;
  margin-top: 36px;
  width: 50%;
}
.create-magazine-area .cm-container .magazine-manager .cloud .upload-wrapper {
  width: 100%;
  height: 110px;
  display: flex;
}
.create-magazine-area .cm-container .magazine-manager .cloud .upload-wrapper label {
  color: #D9A800;
  margin: auto;
  font-size: 16px;
  font-weight: 500;
  border-style: none;
  margin-left: 80px;
  margin-top: 24px;
}
.create-magazine-area .cm-container .magazine-manager .cloud .upload-wrapper p {
  font-size: 12px;
  color: #A8A5A5;
  margin: auto;
  top: 90px;
  position: absolute;
}
.create-magazine-area .cm-container .magazine-manager .cloud .upload-wrapper .import-button {
  margin-bottom: 20px;
  display: flex;
  width: 100%;
  height: 85px;
  position: absolute;
  border-bottom: none;
}
.create-magazine-area .cm-container .magazine-manager .cloud .upload-wrapper .import-button input {
  width: 62px;
  height: 62px;
  background-color: #D9A800;
  margin-left: 0;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
}
.create-magazine-area .cm-container .magazine-manager .cloud .upload-wrapper .import-button input[type=file] {
  margin-top: 10px;
}
.create-magazine-area .cm-container .magazine-manager .cloud h4 {
  color: #161616;
  margin-top: 24px;
}
.create-magazine-area .cm-container .magazine-manager .cloud hr {
  width: 95%;
  color: #717171;
}
.create-magazine-area .cm-container .magazine-manager .cloud .posted-magazine {
  width: 95%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 36px;
}
.create-magazine-area .cm-container .magazine-manager .cloud .posted-magazine .magazine-item {
  margin: auto;
  margin-top: 24px;
  width: 100%;
  position: relative;
  display: inline-flex;
  height: 100px;
}
.create-magazine-area .cm-container .magazine-manager .cloud .posted-magazine .magazine-item:hover {
  border: 0.5px #b8b8b8 dotted;
  padding: auto;
}
.create-magazine-area .cm-container .magazine-manager .cloud .posted-magazine .magazine-item img {
  height: 95px;
  margin: auto;
  margin-left: 16px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 5px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}
.create-magazine-area .cm-container .magazine-manager .cloud .posted-magazine .magazine-item h4 {
  font-size: 16px;
  color: #161616;
  margin: auto;
  margin-left: 0px;
  width: 200px;
  text-wrap: wrap;
}
.create-magazine-area .cm-container .magazine-manager .cloud .posted-magazine .magazine-item p {
  font-size: 14px;
  color: #707070;
  margin: auto;
}
.create-magazine-area .cm-container .magazine-manager .cloud .posted-magazine .magazine-item button {
  bottom: 26px;
  right: 0px;
  background: transparent;
  color: #D9A800;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
}
.create-magazine-area .cm-container .magazine-manager .cloud .posted-magazine .magazine-item button:hover {
  color: #161616;
}
.create-magazine-area .cm-container .magazine-manager .magazine-preview {
  height: 80vh;
  width: 50%;
  margin: auto;
  right: 100px;
  border-left: 1px #D9D9D9 solid;
}
.create-magazine-area .cm-container .magazine-manager .magazine-preview label {
  color: #D9A800;
  margin: auto;
  font-size: 16px;
  font-weight: 500;
  border-style: none;
  margin-left: 80px;
  margin-top: 24px;
}
.create-magazine-area .cm-container .magazine-manager .magazine-preview p {
  font-size: 12px;
  color: #A8A5A5;
  margin: auto;
  text-align: center;
  margin-top: 24px;
}
.create-magazine-area .cm-container .magazine-manager .magazine-preview h3 {
  margin: auto;
  text-align: center;
}
.create-magazine-area .cm-container .magazine-manager .magazine-preview .navigation {
  width: 100%;
  height: 100%;
}
.create-magazine-area .cm-container .magazine-manager .magazine-preview .navigation button {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-left: 24px;
  position: absolute;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
.create-magazine-area .cm-container .magazine-manager .magazine-preview .navigation #left-btn {
  transform: rotateZ(180deg);
  margin-top: 300px;
  -webkit-transform: rotateZ(180deg);
  -moz-transform: rotateZ(180deg);
  -ms-transform: rotateZ(180deg);
  -o-transform: rotateZ(180deg);
}
.create-magazine-area .cm-container .magazine-manager .magazine-preview .navigation #right-btn {
  margin-right: 24px;
  margin-top: 358px;
}
.create-magazine-area .cm-container .magazine-manager .magazine-preview .navigation .preview-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20prgb 255, 0, 162;
  width: 100%;
  height: 100%;
}
.create-magazine-area .cm-container .magazine-manager .magazine-preview .navigation .preview-container .preview-image {
  margin: auto;
  width: 1000px;
  height: 69%;
  object-fit: contain;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 4px;
  display: flex;
}
.create-magazine-area .cm-container .magazine-manager .magazine-preview .navigation .preview-container h2 {
  margin: auto;
  text-align: center;
  margin-top: -24px;
  width: 100%;
}
.create-magazine-area .cm-container .magazine-manager .magazine-preview img {
  width: 100%;
  max-width: 400px;
  height: auto;
  border: 1px solid #ddd;
  border-radius: 4px;
}
.create-magazine-area .cm-container .magazine-manager .magazine-preview h2 {
  margin-top: 10px;
  font-size: 24px;
  color: #333;
}
.create-magazine-area .cm-container .magazine-manager .magazine-preview .mag-title {
  font-size: 18px;
  color: #555;
}
.create-magazine-area .cm-container .title-preview {
  margin-bottom: 20px;
}
.create-magazine-area .cm-container .title-preview label {
  font-size: 16px;
  color: #333;
}
.create-magazine-area .cm-container .title-preview input {
  font-size: 18px;
  font-weight: bold;
  border: none;
  background-color: #f5f5f5;
}
.create-magazine-area .cm-container button {
  background-color: #ffaa00;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s;
}
.create-magazine-area .cm-container button:disabled {
  background-color: #ddd;
  cursor: not-allowed;
}
.create-magazine-area .cm-container button:hover:enabled {
  background-color: #ff8a00;
}

.footer-navigation {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

@media (max-width: 767px) {
  .accordion-item {
    width: 100%;
  }
  .accordion-item .accordion-header {
    margin-left: 24px;
  }
  .accordion-item .Year_2024 .mag_row {
    width: 100%;
    display: inline-flex;
    overflow-x: scroll;
  }
  .accordion-item .Year_2023 .mag_row {
    width: 100%;
    display: inline-flex;
    overflow-x: scroll;
  }
  .accordion-item .Year_2022 .mag_row {
    width: 100%;
    display: inline-flex;
    overflow-x: scroll;
  }
}

