.content{
    // height: 100vh;
    width: 100%;
    display: flex;
    font-family: Georgia, 'Times New Roman', Times, serif;


    .row{
        width: 100%;
        padding: 0;
        margin: 0;

        .col{
            padding: 0;
            margin: 0;
            border: 0px 3px  solid #FFFDF8;
            display: flex;

            img{
                width: 90%;
                margin: auto;
            }

        }

        #bottom-image{
            background-color: #07070A;
        }

        .col-4{
            background-color: #161616;
        }

        .col-8{
            display: flex;
        }

        #top-text{
            background-color: #161616;
            justify-content: center;
            display: flex;

            img{
                height: 54px;
                width: 54px;
                position: absolute;
                margin-top: 12%;
            }

            h3{
                color: #FCFCFC;
                margin-top: 18%;
                font-size: 22px;
                font-weight:300;
                text-align: center;
                position: absolute;
            }

            a{   

                margin-top: 22%;
                position: absolute;
                text-decoration: none;

                    p{
                    color: #D9A800;
                    font-size: 24px;
                    font-weight: 300;
                    }
            }
        }

        #bottom-text{
            background-color: #EAD9C4;
            display: flex;

            .magz-promo{
                position: absolute;
                margin: auto;
                min-height: 60px;
                // background-color: red;
                display: flex;
                justify-content: center;
                margin-top: 10%;
                margin-left: 10%;

                img{
                    height: 54px;
                    width: 54px;
                }

                h3{
                    text-align: center;
                    margin-left: 20px;
                    margin-top: 15px;
                    font-size: 22px;
                }
            }

            p{
                margin-top: 15%;
                position: absolute;
                margin-left: 10%;

                a{
                    text-decoration: none;
                    font-weight: 400;
                    color:#D9A800;
                }
            }
        }
    }
}


// Media Query for Mobile
@media (max-width: 768px) {
    .content {
      flex-direction: column;

      .row {
        .col {
          border: none;
        }

        #top-text {
          img {
            margin-top: 10%;
            height: 16px;
            width: 16px;
          }

          h3 {
            margin-top: 15%;
            font-size: 1rem;
          }

          a {
            margin-top: 20%;

                p{
                    font-size: 1rem;
                    text-align: center;
                }
            }
        }

        #bottom-text {
          .magz-promo {
            margin-top: 5%;
            margin-left: 1px;

            img{
                height: 16px;
                width: 16px;
                position: absolute;
            }

            h3{
                margin-top: 10%;
                
                font-size: .9rem;
            }
          }


          p {
            margin-top: 20%;
            font-size: .7rem;
            text-align: left;
            left: 1px;
            width: 250px;
          }
        }
      }
    }
  }