.nav-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  padding: 0;
  color: whitesmoke;
  height: 50px;
  width: 100%;
  background-color: #FFFDF8;
}
.nav-wrapper #nav-logo {
  height: 48px;
  width: 55px;
  left: 100px;
  position: absolute;
  display: flex;
  display: none;
}
.nav-wrapper .nav-placeholder {
  display: none;
}
.nav-wrapper .nav {
  --ff-size: clamp(1rem, 3vw, 1.1rem);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  isolation: isolate;
  z-index: 1;
  font-size: var(--ff-size);
  letter-spacing: -0.5px;
  transition: all 0.3s ease;
}
.nav-wrapper .nav:is(:hover, :focus-within) .nav__slider {
  opacity: 1;
  transition: opacity 0.1s ease 0.05s;
}
.nav-wrapper .nav__slider {
  position: absolute;
  left: 0;
  z-index: -1;
  opacity: 0;
  border-radius: 0.25rem;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: inset -1px -1px 1px 0 rgba(45, 45, 45, 0.25), 0 0 calc(var(--ff-size) * 1) rgba(255, 255, 255, 0.075);
  transition: opacity 0.2s ease;
}
.nav-wrapper .nav__item {
  color: #161616;
  text-decoration: none;
  padding: 0.25rem calc(var(--ff-size) / 2.5);
  cursor: pointer;
  transition: color 0.2s ease;
}
.nav-wrapper .nav__item:is(:hover, :focus-visible, :active) {
  color: #D9A800;
  font-weight: bold;
}
.nav-wrapper .socials {
  margin: auto;
  width: 190px;
  display: flex;
  display: none;
  right: 100px;
  position: absolute;
}
.nav-wrapper .socials .social {
  margin: auto;
  display: flex;
  height: 25px;
  width: 25px;
  padding: 5px;
}
.nav-wrapper .socials .social:hover {
  transform: translateY(2px);
  -webkit-transform: translateY(2px);
  -moz-transform: translateY(2px);
  -ms-transform: translateY(2px);
  -o-transform: translateY(2px);
}
.nav-wrapper .socials #social-one {
  width: 45px;
}

.nav-wrapper.scrolled {
  position: fixed;
  top: 0;
  z-index: 1000;
  box-shadow: 2px #161616;
  box-shadow: 10px 10px 8px rgba(22, 22, 22, 0.1);
  /* Styling for when the navbar is scrolled */
}
.nav-wrapper.scrolled #nav-logo {
  display: flex;
}
.nav-wrapper.scrolled .socials {
  display: inline-flex;
}

@media (max-width: 768px) {
  .nav-wrapper.scrolled {
    background-color: #FFFDF8;
    position: fixed;
    top: 94.5%;
    z-index: 1000;
    box-shadow: 2px #161616;
    box-shadow: -1px -10px 8px rgba(22, 22, 22, 0.1);
    /* Styling for when the navbar is scrolled */
  }
  .nav-wrapper.scrolled #nav-logo {
    display: none;
  }
  .nav-wrapper.scrolled .nav-placeholder {
    display: flex;
    height: 45px;
    width: 100%;
    background-color: #D9A800;
    top: 0;
    position: fixed;
    box-shadow: 2px #161616;
    box-shadow: 1px 10px 8px rgba(22, 22, 22, 0.1);
    /* Styling for when the navbar is scrolled */
  }
  .nav-wrapper.scrolled .nav-placeholder img {
    height: 40px;
    width: 40px;
    margin: auto;
    margin-left: 16px;
  }
  .nav-wrapper.scrolled .nav-placeholder #mobile_socials {
    display: flex;
    height: 30px;
    position: absolute;
    margin: auto;
    right: 20px;
    top: 5px;
  }
  .nav-wrapper.scrolled .nav-placeholder #mobile_socials .social {
    height: 20px;
    width: 20px;
    margin: auto;
  }
  .nav-wrapper.scrolled .nav-placeholder #mobile_socials .social img {
    height: 20px;
    width: 20px;
  }
  .nav-wrapper.scrolled .nav-placeholder #mobile_socials #social-one {
    width: 45px;
  }
  .nav-wrapper.scrolled .nav-placeholder #mobile_socials #social-one img {
    width: 45px;
  }
  .nav-wrapper.scrolled .socials {
    display: none;
  }
}

