.header-wrapper {
  height: 250px;
  width: 100%;
}
.header-wrapper .soicals-wrapper {
  height: 40px;
  width: 100%;
  background-color: #D9A800;
  display: flex;
}
.header-wrapper .soicals-wrapper .socials {
  margin: auto;
  width: 200px;
  display: flex;
  margin-right: 100px;
}
.header-wrapper .soicals-wrapper .socials .social {
  margin: auto;
  display: flex;
  height: 25px;
  width: 25px;
  padding: 5px;
}
.header-wrapper .soicals-wrapper .socials .social:hover {
  transform: translateY(2px);
  -webkit-transform: translateY(2px);
  -moz-transform: translateY(2px);
  -ms-transform: translateY(2px);
  -o-transform: translateY(2px);
}
.header-wrapper .soicals-wrapper .socials #social-one {
  width: 45px;
}
.header-wrapper .soicals-wrapper .socials #internal-link {
  height: 28px;
}
.header-wrapper .logo-banner {
  height: 210px;
  width: 100%;
  background-color: #FFFDF8;
  background-image: url("./Images/banner_bg.svg");
  background-position: center;
  background-size: cover;
  display: flex;
}
.header-wrapper .logo-banner .logo {
  height: 160px;
  width: 160px;
  margin: auto;
}

