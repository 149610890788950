.PostFromPost{

    display: flex;
    flex-wrap: wrap;
    gap: 20px; /* Adjust the gap between posts as needed */
    margin-top: 24px;


    .post{
        box-sizing: border-box;
        margin: auto;
 
        width: 307px;
        height: 503px;
        flex-shrink: 0;
        border-radius: 5px;
        background: #FFF;
        box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.16);


        .postheader{
            height: 330px;
            display: block;
           
            img{
                width: 282px;
                height: 270px;
                flex-shrink: 0;
                margin-left: 12px;
                margin-top: 14px;
            }

            .title{
                width: 100%;
                height: 30px;
                margin-top: 8px;
                display: flex;
                position: sticky;

                h1{
                    color: #000;
                    font-family: Roboto;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 800;
                    line-height: normal;
                    margin-left: 12px;
                    position: absolute;
                    margin-top: 5px;
                    width: 80%;

                }

                .deletePost{
                    margin-left: auto; /* Push the delete button to the right */
                    margin-right: 12px; /* Optional: Add some right margin for spacing */
                    margin-top: 5px;
                    border: none;
                }
            }
        }

        .postTextContainer{
            width: 285px;
            height: auto;
            flex-shrink: 0;
            color: #000;
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-left: 12px;
            margin-top: 5%;
            
            
            a{
                color:#D9A800;
            }
            
        }

        h3{
            width: 308px;
            // height: auto;
            flex-shrink: 0;
            color: #ccc;
            font-family: Roboto;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-left: 12px;
            margin-top: 10px; 

        }

        .Datetime{
            width: 308px;
            color: #ccc;
            font-family: Roboto;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-left: 12px;
            margin-top: 5px; 

        }
    }

    // Media Query for Mobile Devices
    @media (max-width: 767px) {

        display:flex;
        flex-wrap: nowrap;
        margin: auto;
        margin-top:36px;
    }
}