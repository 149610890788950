.services {
  height: 45vh;
  width: 100%;
  background-color: #161616;
  display: flex;
  overflow: hidden;
}
.services .col {
  height: 100%;
  width: 25%;
  display: flex;
  margin: auto;
  transition: width 2s;
  justify-content: center;
  position: static;
  overflow: hidden;
}
.services .col video {
  opacity: 0.5;
}
.services .col h3 {
  text-align: center;
  margin: auto;
  margin-top: 20vh;
  font-size: 22px;
  font-family: "Roboto light";
  color: #FFFDF9;
  transition: margin 3s;
  -webkit-transition: margin 3s;
  -moz-transition: margin 3s;
  -ms-transition: margin 3s;
  -o-transition: margin 3s;
  position: absolute;
}
.services .col p {
  text-align: center;
  margin: auto;
  font-size: 16px;
  font-family: "Roboto";
  position: absolute;
  margin-top: 250px;
  width: 400px;
  display: none;
  color: #FFFDF9;
  transition: margin 6s;
  -webkit-transition: margin 6s;
  -moz-transition: margin 6s;
  -ms-transition: margin 6s;
  -o-transition: margin 6s;
}
.services .col:hover {
  width: 600px;
}
.services .col:hover h3 {
  margin-top: 120px;
  color: #D9A800;
}
.services .col:hover p {
  display: flex;
  margin-top: 180px;
  transition: margin 6s;
  -webkit-transition: margin 6s;
  -moz-transition: margin 6s;
  -ms-transition: margin 6s;
  -o-transition: margin 6s;
}
.services #service-four:hover {
  width: 500px;
}
.services #service-four:hover p {
  margin-top: 180px;
  transition: margin 6s;
  -webkit-transition: margin 6s;
  -moz-transition: margin 6s;
  -ms-transition: margin 6s;
  -o-transition: margin 6s;
  width: 480px;
}

@media (max-width: 768px) {
  .services {
    height: auto;
    flex-wrap: wrap;
    overflow-x: auto;
    overflow-y: hidden;
    display: block;
    white-space: wrap;
  }
  .services .col {
    display: inline-block;
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
  .services .col video {
    width: 100%;
  }
  .services .col h3 {
    margin-top: -20%;
    position: static;
    color: #d9a800;
    z-index: 5000;
    opacity: 0.9;
  }
  .services .col p {
    display: block;
    margin-top: 20px;
    width: 100%;
    position: static;
    padding: 5%;
    opacity: 0.9;
  }
  .services .col:hover {
    width: 100%;
  }
  .services .col:hover h3 {
    margin-top: 20px;
    color: #d9a800;
  }
  .services .col:hover p {
    display: block;
    margin-top: 20px;
  }
  .services #service-four:hover {
    width: 100%;
  }
  .services #service-four:hover p {
    margin-top: 20px;
    width: 100%;
  }
}

