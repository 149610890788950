@import url("https://fonts.googleapis.com/css2?family=Hurricane&display=swap");
.Mags_header {
  height: 400px;
  width: 100%;
  background: linear-gradient(rgba(13, 13, 13, 0.4), rgba(13, 13, 13, 0.4)), url("Images/Flat_Lay_Book.jpg");
  background-position: center;
  background-size: cover;
}
.Mags_header h2 {
  font-size: 250px;
  font-family: "Hurricane";
  margin-left: 151px;
  color: #D9A800;
  position: relative;
}
.Mags_header h4 {
  font-size: 44px;
  font-family: "Roboto";
  font-weight: 500;
  margin-left: 151px;
  color: #D9D9D9;
  margin-top: -60px;
}

@media (max-width: 767px) {
  .Mags_header {
    display: flex;
  }
  .Mags_header h2 {
    margin: auto;
    margin-left: 24px;
    font-size: 114px;
    position: absolute;
    margin-top: 30%;
    z-index: 1;
  }
  .Mags_header h4 {
    font-size: 24px;
    margin: auto;
    margin-left: 36px;
    position: absolute;
    margin-top: 55%;
  }
}

