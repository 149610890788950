.landing{
    // position: absolute;
    height: 100vh;
    width: 100%;
    display: flex;
    background-color: black;

    .desktop_landing{
        height: 100%;
        width: 100%;
    }

    .mobile_landing{
        display: none;
    }
}

 // Media Query for Mobile
@media (max-width: 768px) {
    .landing {
      position: static;
      flex-direction: column;
      background-color: #FCFCFC;
      height: auto;

        video {
            height: auto;
        }

        .desktop_landing{
            display: none;
        }

        .mobile_landing{
            display: block;
        }
    }
}