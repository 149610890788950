.accordion-item-2 {
  width: 95%;
  min-height: auto;
  margin: auto;
  margin-top: 100px;
  margin-bottom: 100px;
  border: none;
  background-color: #FFFDF9;
}
.accordion-item-2 .accordion-header {
  display: flex;
}
.accordion-item-2 .accordion-header h3 {
  color: #D9D9D9;
  font-size: 36px;
  font-family: "Roboto";
  font-weight: 400;
  word-wrap: break-word;
  cursor: pointer;
}
.accordion-item-2 .accordion-header h3:hover {
  color: #D9A800;
}
.accordion-item-2 .accordion-header span {
  position: absolute;
  right: 8px;
  color: #D9D9D9;
}

@media (max-width: 767px) {
  .accordion-item-2 {
    width: 100%;
  }
  .accordion-item-2 .accordion-header {
    margin-left: 24px;
  }
  .accordion-item-2 .accordion-content {
    width: 100%;
    display: inline-flex;
    overflow-x: scroll;
  }
  .accordion-item-2 .Year_2022 .mag_row {
    width: 100%;
    display: inline-flex;
    overflow-x: scroll;
  }
}

