@import url('https://fonts.googleapis.com/css2?family=Hurricane&display=swap');

.Bl_Header{
    height: 400px;
    width: 100%;
    background: linear-gradient(rgba(13, 13, 13, 0.4), rgba(13,13 , 13, 0.4)),url('Images/Read.jpg');
    background-position: center;
    background-size: cover;

    h2{
        font-size: 250px;
        font-family: 'Hurricane';
        margin-left: 151px;
        color: #D9A800;
        position: relative;
    }

    h4{
        font-size: 44px;
        font-family: 'Roboto';
        font-weight: 500;
        margin-left: 151px;
        color: #D9D9D9;
        margin-top: -60px;
        
    }
}

 // Media Query for Mobile Devices
 @media (max-width: 767px) {

    .Bl_Header{
        display: flex;

        h2{
            margin: auto;
          margin-left: 24px;
        //   margin-top: 36px;
          font-size: 114px;
          position: absolute;
          margin-top:30%;
          z-index: 1;
        }

        h4{
            font-size: 24px;
            margin: auto;
            margin-left: 36px;
            position: absolute;
            margin-top:55%
            
        }
    }
}