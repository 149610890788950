.header-wrapper{
    // position: absolute;
    height: 250px;
    width: 100%;
    // background-color: red;
    // display: flex;

    .soicals-wrapper{
        // position: absolute;
        height:40px;
        width: 100%;
        background-color: #D9A800;
        display: flex;

        .socials{
            margin: auto;
            width:200px;
            display: flex;
            margin-right: 100px;

            .social{
                margin: auto;
                display: flex;
                height: 25px;
                width: 25px;
                padding: 5px;
            }

            .social:hover{
                transform: translateY(2px);
                -webkit-transform: translateY(2px);
                -moz-transform: translateY(2px);
                -ms-transform: translateY(2px);
                -o-transform: translateY(2px);
            }

            #social-one{
                width: 45px;
            }

            #internal-link{
                height: 28px;
            }

        }

    }

    .logo-banner{
        // position: absolute;
        height: 210px;
        width: 100%;
        background-color: #FFFDF8;
        background-image: url('./Images/banner_bg.svg');
        background-position: center;
        background-size: cover;
        display: flex;

        .logo{
            height: 160px;
            width: 160px;
            margin: auto;
        }
    }

}

