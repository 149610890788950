.about_us {
  height: 100vh;
  width: 100%;
  display: flex;
  background-image: url("./Images/About-Us-cover.png");
  background-position: center;
  background-size: cover;
}
.about_us .left_block {
  position: absolute;
  display: flex;
  left: 150px;
  height: 750px;
  width: 700px;
  background: rgb(70, 70, 70);
  background: linear-gradient(0deg, rgba(70, 70, 70, 0) 10%, rgb(70, 70, 70) 34%, rgba(255, 255, 255, 0) 87%);
}
.about_us .left_block h3 {
  font-size: 32px;
  font-weight: bold;
  top: 200px;
  left: 80px;
  color: #FFFDF9;
  position: absolute;
}
.about_us .left_block p {
  position: absolute;
  bottom: 150px;
  left: 80px;
  right: 100px;
  font-size: 16px;
  font-weight: 500;
  color: #FFFDF9;
  width: 550px;
  margin-right: 100px;
}
.about_us .right_block {
  height: 600px;
  width: 300px;
  display: flex;
  position: absolute;
  right: 100px;
  margin-top: 250px;
}
.about_us .right_block .profile_image {
  height: 250px;
  width: 250px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  background-color: #FFFDF9;
  margin: auto;
  margin-top: 100px;
  display: flex;
}
.about_us .right_block .profile_image img {
  height: 180px;
  width: 180px;
  margin: auto;
}

@media (max-width: 767px) {
  .about_us {
    flex-direction: column;
    width: 100%;
    overflow: hidden;
    margin: auto;
    background-image: url("./Images/About-Us-cover-For-Mobile.png");
    background-position: center;
    background-size: cover;
    background-color: #464545;
  }
  .about_us .left_block {
    left: 0;
    margin-top: 75%;
    height: 65vh;
    width: 100%;
    margin-bottom: 0;
    background: rgb(70, 70, 70);
    background: linear-gradient(180deg, rgba(70, 70, 70, 0.1587228641) 0%, rgba(70, 70, 70, 0) 79%, rgba(255, 255, 255, 0) 100%);
  }
  .about_us .left_block h3 {
    top: 50px;
    left: 24px;
    color: #D9A800;
  }
  .about_us .left_block p {
    bottom: 0;
    left: 24px;
    width: 80%;
    margin-right: 0;
    font-size: 1rem;
  }
  .about_us .right_block {
    display: none;
  }
}

