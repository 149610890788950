@import url("https://fonts.googleapis.com/css2?family=Hurricane&display=swap");
.magazine {
  height: 75vh;
  width: 100%;
  background-color: #FFFDF8;
  overflow: none;
}
.magazine .magazine_hero {
  height: 250px;
  display: flex;
  height: 210px;
  width: 100%;
  background-color: #FFFDF8;
  background-image: url("./Images/banner_bg.svg");
  background-position: center;
  background-size: cover;
  display: flex;
}
.magazine .magazine_hero h3 {
  color: #000;
  font-family: "Hurricane";
  font-size: 105px;
  font-style: normal;
  font-weight: 400;
  line-height: 173px;
  /* 164.762% */
  text-align: center;
  margin: auto;
}
.magazine .landing_mags_wrapper {
  height: 50vh;
  width: 95%;
  display: flex;
  overflow: auto;
  white-space: nowrap;
  margin: auto;
}
.magazine .landing_mags_wrapper .magz {
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  padding: 5px;
}
.magazine .landing_mags_wrapper .magz img {
  width: 220px;
  height: 310.59px;
  flex-shrink: 0;
  transition: all 0.2s ease-in-out;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}
.magazine .landing_mags_wrapper .magz h4 {
  font-size: 16px;
  color: #262626;
  margin-top: 15px;
  text-wrap: wrap;
}
.magazine .landing_mags_wrapper .magz:hover {
  cursor: pointer;
}
.magazine .landing_mags_wrapper .magz:hover img {
  transform: scale(1.1);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
}
.magazine .landing_mags_wrapper .magz:hover h4 {
  font-weight: bolder;
}
.magazine .landing_mags_wrapper #View-more {
  height: 100%;
  text-decoration: none;
}
.magazine .landing_mags_wrapper #View-more .next {
  width: 220px;
  display: flex;
}
.magazine .landing_mags_wrapper #View-more:hover .next {
  transform: scale(1);
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
}
.magazine .landing_mags_wrapper #View-more:hover .next img {
  transform: scale(1.1);
  box-shadow: none;
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
}
.magazine .landing_mags_wrapper #View-more:hover h4 {
  color: #D9A800;
}
@media (max-width: 767px) {
  .magazine {
    flex-wrap: wrap;
    display: block;
  }
  .magazine .magazine_hero h3 {
    font-size: 114px;
  }
  .magazine .magz {
    display: inline-block;
    width: 300px;
  }
  .magazine .magz .magz-footer {
    position: absolute;
    bottom: 0;
    width: 100%;
  }
  .magazine .magz .magz-footer h4 {
    position: static;
    margin-left: 0;
    text-align: center;
  }
}

