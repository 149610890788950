.Signedin {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* Center items horizontally */
  justify-content: center;
  /* Center items vertically */
  position: relative;
  color: #FFFDF9;
  background-position: center;
  background-size: cover;
  background-image: linear-gradient(rgba(13, 13, 13, 0.4), rgba(13, 13, 13, 0.7)), url("Images/Black.jpg");
}
.Signedin .wrapper {
  margin: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.Signedin .wrapper img {
  height: 85px;
  width: 85px;
  background-color: #D9D9D9;
  border-radius: 50px;
  margin-top: 20px;
}
.Signedin .wrapper p {
  color: #FCFCFC;
  font-family: "Roboto";
}
.Signedin .wrapper h3 {
  color: #FCFCFC;
  font-family: "Roboto";
  margin: 20px 0;
}
.Signedin .wrapper #loggedin {
  margin-top: 10px;
}
.Signedin .wrapper .option-wrapper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 20px 0;
}
.Signedin .wrapper .option-wrapper .options {
  height: 154px;
  width: 233px;
  background-color: #FCFCFC;
  border-radius: 5px;
  border-bottom: 13px solid #D9A800;
  color: black;
  margin: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: "Roboto";
  font-size: 18px;
  cursor: pointer;
  text-decoration: none;
  color: black;
  /* Ensure link text is black */
  transition: transform 0.2s;
}
.Signedin .wrapper .option-wrapper .options:hover {
  transform: scale(1.05);
}
.Signedin .wrapper #log-out, .Signedin .wrapper #backhome {
  color: #FCFCFC;
  cursor: pointer;
  margin: 10px 0;
}
.Signedin .wrapper #backhome {
  color: #D9A800;
  text-decoration: none;
}

