.createblog-area{
    
    background-color: #F5F5F5;
    min-height: 100vh;

    .cb-Container{
      min-height: 100vh;
      width: 100%;
    //   background-color: rgb(136, 118, 118);
      display: flex;

        .title{
            display: flex;
            width: 100%;
            height: 85px;
            position: absolute;
            background-color:#FFFDF9;
            border-bottom: 2px #161616 solid;

            label{
                color: #161616;
                margin-left: 16px;
                font-size: 24px;
                font-weight: 500;
                border-style: none;
            }

            input{
                height: 50px;
                width: 600px;
                position: absolute;
                margin-top: 30px;
                left:24px;
                border: none;
                background-color:#FFFDF9;
                border-style: none
            }

            input:focus {
                outline: none; /* Remove the default focus border */
            }

            button{
                right: 16px;
                position: absolute;
                width: 150px;
                height: 45px;
                margin-top: 30px;
                background-color: #D9A800;
                cursor: pointer;
                
            }

            button:hover{
                background-color:#FFFDF9;
            }
        }

        .post{
            margin-top: 80px;
            background-color: #F5F5F5;
            height: 100vh;
            width: 100%;
            display: flex;
            // position: absolute;

            .uploadimagearea{
                background-color: #D9A800;
                height: 400px;
                width: 100%;
                position: absolute;
                display: flex;
                flex-direction: column;
                justify-content: center; // Center items vertically
                align-items: center; // Center items horizontally
                margin-top: 5px;
              
                label {
                  text-align: center;
                  color: 161616;
                  // margin-bottom: 80px; // Add margin between label and input
                  position: absolute;
                  font-size: 24;
                  font-weight: 400;
                  top: 16px;
                }
              
                input {
                  text-align: center;
                  // margin-bottom: 10px; // Add margin between input and imageUpload
                  position: absolute;
                  width: 100px;
                  top: 48px;
                }
              
                .imageUpload {
                  width: 100%;
              
                  img {
                    object-fit: cover; // Maintain aspect ratio and cover the container
                    object-position: top; // Align the image to the top
                    width: 100%;
                    height: 400px;
                  }
                }
                
            }

            textarea{
                position: absolute;
         
                // margin:auto;
                margin-top: 150px;
                margin-left: 20.5%;
                padding: 10px;
                background-color: #FFFDF9;
                width: 59%;
                min-height: 500px;
              
                // resize: none; // Disable resizing
                outline: none; // Remove the default focus border
                border: none;
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); // Box shadow for a subtle effect

                // overflow-y: hidden; // Hide the vertical scrollbar initially
                transition: height 0.3s ease; // Add smooth transition for height changes


            }
        }
    }


    // Media Query for Mobile Devices
    @media (max-width: 767px) {

      .cb-Container{
        overflow: hidden;

        .title{


          input{
            width: 64%;
          }

          button{
            width: 110px;

          }

        }

        .post{

          textarea{
            width: 90%;
            margin-left: 16px;
            font-size: 14px;
            min-height: 600px;
            
         
          }
        }
      }
    } 
}