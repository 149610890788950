.nav-wrapper{
    display: flex;
	justify-content: center;
	align-items: center;
    left: 0;
    padding: 0;

	color: whitesmoke;

	height: 50px;
    width: 100%;

	background-color:#FFFDF8;
	// background-image: linear-gradient(45deg, #191919, #4f4f4f);

    #nav-logo{
        height:48px;
        width:55px;
        // background-color: black;
        left: 100px;
        position: absolute;
        display: flex;
        display: none;
    }

    .nav-placeholder{
        display: none;
    }

    .nav{
        --ff-size: clamp(1rem, 3vw, 1.1rem);

        display: flex;
        justify-content: center;
        align-items: center;

        position: relative;
        isolation: isolate;
        z-index: 1;

      
        font-size: var(--ff-size);
        letter-spacing: -0.5px;

        transition: all 0.3s ease;
    }

    .nav:is(:hover, :focus-within) .nav__slider {
        opacity: 1;
        transition: opacity 0.1s ease 0.05s;
    }

   
    
    .nav__slider {
        position: absolute;
        // top: 0;
        left: 0;
        z-index: -1;
    
        opacity: 0;
        border-radius: 0.25rem;
        background-color: rgba(255, 255, 255, 0.9);
        box-shadow: inset -1px -1px 1px 0 rgba(45, 45, 45, 0.25),
            0 0 calc(var(--ff-size) * 1) rgba(255, 255, 255, 0.075);
    
        transition: opacity 0.2s ease;
    }
    
    .nav__item {
        color: #161616;
        text-decoration: none;
    
        padding: 0.25rem calc(var(--ff-size) / 2.5);
    
        cursor: pointer;
    
        transition: color 0.2s ease;
    }
    
    .nav__item:is(:hover, :focus-visible, :active) {
        color: #D9A800;
        font-weight: bold;
    }



    .socials{
        margin: auto;
        width:190px;
        display: flex;
        display: none;
        right: 100px;
        position: absolute;
    
        .social{
            margin: auto;
            display: flex;
            height: 25px;
            width: 25px;
            padding: 5px;
        }

        .social:hover{
            transform: translateY(2px);
            -webkit-transform: translateY(2px);
            -moz-transform: translateY(2px);
            -ms-transform: translateY(2px);
            -o-transform: translateY(2px);
        }
    
        #social-one{
            width: 45px;
        }
    
    }       

}

.nav-wrapper.scrolled {
    position: fixed;
    top: 0;
    z-index: 1000;
    box-shadow: 2px #161616;
    box-shadow: 10px 10px 8px rgba($color: #161616, $alpha: .1);
    /* Styling for when the navbar is scrolled */

    #nav-logo{
        display: flex;
    }

    .socials{
        display: inline-flex;
    }
  }


// Media Query for Mobile
@media (max-width: 768px) {
    // Styles for screens smaller than 768px



    .nav-wrapper.scrolled {
        background-color:#FFFDF8;
        position: fixed;
        top: 94.5%;
        z-index: 1000;
        box-shadow: 2px #161616;
        box-shadow: -1px -10px 8px rgba($color: #161616, $alpha: .1);
        /* Styling for when the navbar is scrolled */
    
        #nav-logo{
            display: none;
        }

        .nav-placeholder{
            display: flex;

            height: 45px;
            width: 100%;
            background-color:#D9A800;
            top: 0;
            position: fixed;

            box-shadow: 2px #161616;
            box-shadow: 1px 10px 8px rgba($color: #161616, $alpha: .1);
            /* Styling for when the navbar is scrolled */

            img{
                height: 40px;
                width: 40px;
                margin: auto;
                margin-left: 16px;
            }

            #mobile_socials{
                 display: flex;
                 height: 30px;
                 position: absolute;
                 margin: auto;
                 right: 20px;
                 top: 5px;

                .social{
                    height: 20px;
                    width: 20px;
                    margin: auto;

                    img{
                        height: 20px;
                        width: 20px;
                    }
                }

                #social-one{
                    width: 45px;

                    img{
                        width: 45px;
                    }
                }
            }
        }
    
    
        .socials{
           
            display: none;
        }
    }
}