.services{
    // position: absolute;
    height: 45vh;
    width: 100%;
    background-color:#161616;
    display: flex;
    overflow: hidden;

    .col{
        height: 100%;
        width: calc(100%/4);
        display: flex;
        margin: auto;
        transition: width 2s;
        justify-content: center;
        position: static;
        overflow: hidden;


        video {
        opacity: .5;
        }

        h3{
            text-align: center;
            margin: auto;
            margin-top: 20vh;
            font-size: 22px;
            font-family: "Roboto light";
            color:#FFFDF9;
            transition: margin 3s;
            -webkit-transition: margin 3s;
            -moz-transition: margin 3s;
            -ms-transition: margin 3s;
            -o-transition: margin 3s;
            position: absolute;
        }

        p {
            text-align: center;
            margin: auto;
            font-size: 16px;
            font-family: "Roboto";
            position: absolute;
            margin-top: 250px;
            width: 400px;
            display: none;
            color: #FFFDF9;
            transition: margin 6s;
            -webkit-transition: margin 6s;
            -moz-transition: margin 6s;
            -ms-transition: margin 6s;
            -o-transition: margin 6s;
        }
    }

    .col:hover{
        width: 600px;

        h3{
            margin-top: 120px;
            color: #D9A800;
        }

        p{
            display: flex;
            margin-top: 180px;
            transition: margin 6s;
            -webkit-transition: margin 6s;
            -moz-transition: margin 6s;
            -ms-transition: margin 6s;
            -o-transition: margin 6s;
        }
    }


    #service-four:hover{
        width: 500px;

        p{
            // display: flex;
            margin-top: 180px;
            transition: margin 6s;
            -webkit-transition: margin 6s;
            -moz-transition: margin 6s;
            -ms-transition: margin 6s;
            -o-transition: margin 6s;

            width: 480px;
        }
    }

}


  // Media Query for Mobile
@media (max-width: 768px) {
    // Styles for screens smaller than 768px

    .services {
      height: auto;

      flex-wrap: wrap;
        overflow-x:auto;
        overflow-y:hidden;
        display: block;
        white-space: wrap;

      .col {

        display: inline-block;
        width: 100%;
        flex-direction: column;
        align-items: center;

        video {
          width: 100%;
        }

        h3 {
          margin-top: -20%;
          position: static;
          color: #d9a800;
          z-index: 5000;
          opacity: .9;
        }

        p {
          display: block;
          margin-top: 20px;
          width: 100%;
          position: static;
          padding: 5%;
          opacity: .9;
        }
      }

      .col:hover {
        width: 100%;

        h3 {
          margin-top: 20px;
          color: #d9a800;
        }

        p {
          display: block;
          margin-top: 20px;
        }
      }

      #service-four:hover {
        width: 100%;

        p {
          margin-top: 20px;
          width: 100%;
        }
      }
    }
  }


