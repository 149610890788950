.IntNavbar {
  width: 100%;
  height: 40px;
  display: flex;
  background: #161616;
  padding: 0 20px;
  /* Add padding for spacing */
  color: #fcfcfc;
  position: fixed;
}
.IntNavbar .logo {
  height: 36px;
  width: 36px;
  margin-left: 24px;
}
.IntNavbar .logo img {
  height: 100%;
}
.IntNavbar h2 {
  font-size: 16px;
  font-weight: 400;
  margin: auto;
  margin-left: 24px;
}
.IntNavbar .CB {
  position: absolute;
  right: 150px;
  margin-top: 8px;
  font-size: 18px;
  text-decoration: none;
  color: #fcfcfc;
}
.IntNavbar .CB:hover {
  color: #D9A800;
  font-weight: 600;
}
.IntNavbar button {
  color: #fcfcfc;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: none;
  margin-top: 4px;
  margin-left: auto;
  /* Push the button and link to the right */
  background-color: #161616;
  margin-right: 5px;
}
.IntNavbar button:hover {
  color: #D9A800;
  font-weight: 600;
}
.IntNavbar .profileImage {
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  height: 34px;
  width: 34px;
}
@media (max-width: 767px) {
  .IntNavbar .logo {
    margin-left: 8px;
  }
  .IntNavbar h2 {
    font-size: 11px;
    margin-left: 12px;
  }
  .IntNavbar .CB {
    font-size: 16px;
    right: 150px;
    top: 1px;
  }
  .IntNavbar button {
    font-size: 16px;
  }
}

