 @mixin Mags_layout{
    margin-top: 36px;
    .mag_row{
        width: 90%;
        height: auto;
        margin: auto;
        margin-top: 32px;
        // background-color: red;
        margin-top: 5%;

        .mag{
            padding:4px;
            cursor: pointer;
        
            img{
                width: 208px;
                height: 282.35px;
                flex-shrink: 0;
                //background-color: green;
                border-radius: 5px;
                background-size:contain;
                background-repeat: no-repeat;
                background-position: center;
                -webkit-border-radius: 5px;
                -moz-border-radius: 5px;
                -ms-border-radius: 5px;
                -o-border-radius: 5px;
}

            img:hover{
               
            }

    
            h4{
                margin-top: 10px;
                color: #262626;
                font-family: 'Roboto';
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                text-wrap: wrap;

                width: 208px;
                
                
            }

            h5{
                color: #707070;
                font-family: 'Roboto';
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
              
            }

            img{
                // background-color: red
                transition: all .2s ease-in-out; 
            }
            
        }

        .mag:hover{
            img{
                transform: scale(1.1); 
                box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
            }
        }

        .autoMag {

           
            h2{
                color: #D9D9D9;
                font-size: 36px;
                font-family: 'Roboto';
                font-weight: 400;
                word-wrap: break-word;
                cursor: pointer;
                margin-bottom: 64px;
            }
         
            h3{
                color:#D9A800;
                margin-top: 24px;
                margin-bottom: 24px;
                
            }
      
              .magazineTiles{
              
              display: flex;
              flex-direction: row;
              height: auto;
              margin: auto;
              width:100%;
              flex-wrap: wrap;
              gap:  30px;

              margin-bottom: 3%;
  
              }
          }

    }

    .row_one{}
    .row_two{
        margin-top: 52px;
    }
}