.accordion-item-2{
    width: 95%;
    min-height: auto;

    margin: auto;
    margin-top: 100px;
    margin-bottom: 100px;
    border: none;
    background-color: #FFFDF9;

    .accordion-header{
        display: flex;
        // position: relative;

        h3{
             // YYYY
            color: #D9D9D9;
            font-size: 36px;
            font-family: 'Roboto';
            font-weight: 400;
            word-wrap: break-word;
            cursor: pointer;
        }

        h3:hover{
            color:#D9A800 ;
        }

        span{
            position: absolute;
            right: 8px;
            color: #D9D9D9;
        }
    }  
}

  // Media Query for Mobile Devices
  @media (max-width: 767px) {


    .accordion-item-2{
        width: 100%;

        .accordion-header{
            margin-left: 24px;
        }

        .accordion-content{
            // background-color: aqua;
        
                width: 100%;
                display: inline-flex;
                overflow-x: scroll;
            

        }
        
        .Year_2022{
            .mag_row{
                width: 100%;
                display: inline-flex;
                overflow-x: scroll;
            }
        }
    }
  }

