@import url("https://fonts.googleapis.com/css2?family=Hurricane&display=swap");
.BRLanding {
  width: 100%;
  overflow: none;
  min-height: auto;
}
.BRLanding .BRLanding-hero {
  height: 250px;
  display: flex;
  height: 210px;
  width: 100%;
  background-color: #FFFDF8;
  background-image: url("./Images/banner_bg.svg");
  background-position: center;
  background-size: cover;
  display: flex;
}
.BRLanding .BRLanding-hero h3 {
  color: #000;
  font-family: "Hurricane";
  font-size: 105px;
  font-style: normal;
  font-weight: 400;
  line-height: 173px;
  /* 164.762% */
  text-align: center;
  margin: auto;
}
.BRLanding .BR_area_wrapper {
  width: 95%;
  overflow: auto;
  margin: auto;
}
@media (max-width: 767px) {
  .BRLanding {
    scroll-behavior: smooth;
    overflow: hidden;
  }
  .BRLanding .BRLanding-hero h3 {
    font-size: 114px;
  }
  .BRLanding .BR_area_wrapper {
    margin: auto;
  }
}

